/*
 * File: AccessControl.config.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

const Config = {
    admin : {
        connexion: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        },
        form: {
        },
        forgotpassword: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        },
        user: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        },
        sellsoffers: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        }
    },
    user : {
        connexion: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        },
        form: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        },
        forgotpassword: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        },
        user: {
        },
        sellsoffers: {
            'read:any': ['*'],
            'delete:any': ['*'],
            'create:any': ['*'],
            'update:any': ['*'],
        }
    }
}

export default Config;
