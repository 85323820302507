/*
 * File: AddUserModal.js
 * Project: front
 * File Created: Tuesday, 25th October 2022 08:30:53
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 4th January 2024 05:08:25
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */


import {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form';
import MyModal from './MyModal';
import translator from "MultiLanguage/TranslationWrapper";
import InputSubmit from "Theme/Component/InputSubmit";
import Input from "Theme/Component/Input";
import Select from "Theme/Component/Select";
import styles from "Content/Modal/AddUserModal.module.css";
import UserService from 'Services/Users/UserService';
import AuthenticationError from "Error/AuthenticationError";
import { useHistory, useLocation } from "react-router-dom";

function AddUser(props) {
    const [ firstname, setFirstName ] = useState("");
    const [ lastname, setLastName ] = useState("");
    const [ company, setCompany ] = useState("");
    const [ adress, setAdress ] = useState("");
    const [ adressComp, setAdressComp ] = useState("");
    const [ city, setCity ] = useState("");
    const [ postalCode, setPostalCode ] = useState("");
    const [ state, setState ] = useState("");
    const [ country, setCountry ] = useState("");
    const [ phoneNumber, setPhone ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ preferedLang, setPreferedLang ] = useState({value: "fr", label: "Français"});
    const [ role, setRole ] = useState({"value": "user", "label":"user"});
    const [ commercialOpt, setCommercialOpt ] = useState(props.adminOptions[0]);
    const [ error, setError] = useState(false);
    const [ errorMessage, setErrorMessage] = useState("");
    const history = useHistory();
    const location = useLocation();

    const roleOptions = [{"value": "admin", "label":"admin"}, {"value": "user", "label":"user"}];
    const langOptions = [{"value": "fr", "label":"Français"}, {"value": "es", "label":"Espagnol"}, {"value": "en", "label":"Anglais"}];
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'gray' : 'default',
          borderBottom: state.isFocused ? '2px solid red' : '0px transparent',
          borderLeft: state.isFocused ? '2px solid red' : '0px transparent'
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            padding: 0,
            display: "flex",
        }),
          singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';

          return { ...provided, opacity, transition };
        }
      }

    const validationFooter = () => {
        return (<InputSubmit onClick={handleSubmit}
            className={"block h-center-div full-width font-weight-700 large-font-2 margin-bottom " + styles.input_submit}
            value={translator.decode("users.addUser.send")}/>)
    }

    const handleSubmit= () => {
        const user = {
            "company": company,
            "firstname": firstname,
            "lastname": lastname,
            "address": adress,
            "addressComp": adressComp,
            "codePostal": parseInt(postalCode),
            "city": city,
            "state": state,
            "country": country,
            "phoneNumber": phoneNumber,
            "email": email,
            "role": role.value,
            "commercialID": commercialOpt.value,
            "preferedLanguage": preferedLang.value,
        }
        let ret = UserService.addUser(user);
        ret.then(response => {
            console.log(response);
            if (response.status == 400 && response.data.errorMessage != undefined) {
                setError(true);
                console.log(response);
                console.log(response.data.errorMessage);
                setErrorMessage(`${JSON.stringify(response.data.errorMessage)}`);
            } else {
                setError(false);
                setErrorMessage("");
                setFirstName("");
                setLastName("");
                setCompany("");
                setAdress("");
                setAdressComp("");
                setCity("");
                setPostalCode("");
                setState("");
                setCountry("");
                setPhone("");
                setEmail("");
                setRole({"value": "user", "label":"user"});
                setPreferedLang({value: "fr", label: "Français"});
                props.onClose();
            }
        }).catch(async (e) => {
            if (e instanceof AuthenticationError) {
                var ret = await UserService.refreshTokens()
                if (ret === false)
                    history.push(`/?return_url=${location.pathname}`);
                else {
                    handleSubmit();
                }
            }
        })
    }

    useEffect(() => {
        console.log(props.adminOptions);
    }, [props.adminOptions])

    return (
    <MyModal show={props.show}
            className={styles.addUserModal}
            title={translator.decode("users.addUser.title")}
            onClose={props.onClose}
            footer={validationFooter()}>
        <Form onSubmit={handleSubmit} id={ styles.div_form } className={styles.centered}>
            <Form.Group className={styles.inputGroup} controlId="company">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.company")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={company}
                    placeholder={translator.decode("users.addUser.placeholder.company")}
                    onChange={e => setCompany(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="firstname">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.firstname")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={firstname}
                    placeholder={translator.decode("users.addUser.placeholder.firstname")}
                    onChange={e => setFirstName(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="lastname">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.lastname")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={lastname}
                    placeholder={translator.decode("users.addUser.placeholder.lastname")}
                    onChange={e => setLastName(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="adress">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.address")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={adress}
                    placeholder={translator.decode("users.addUser.placeholder.address")}
                    onChange={e => setAdress(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="adressComp">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.addressComp")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={adressComp}
                    placeholder={translator.decode("users.addUser.placeholder.addressComp")}
                    onChange={e => setAdressComp(e.target.value)}
                    />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="city">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.city")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={city}
                    placeholder={translator.decode("users.addUser.placeholder.city")}
                    onChange={e => setCity(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="postalCode">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.postal_code")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={postalCode}
                    placeholder={translator.decode("users.addUser.placeholder.postal_code")}
                    onChange={e => setPostalCode(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="state">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.state")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={state}
                    placeholder={translator.decode("users.addUser.placeholder.state")}
                    onChange={e => setState(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="country">
            <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.country")}</Form.Label>
            <Input
                    className={"block h-center-div full-width " + styles.input}
                    type="text"
                    value={country}
                    placeholder={translator.decode("users.addUser.placeholder.country")}
                    onChange={e => setCountry(e.target.value)}
                    required={true}
                     />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="phoneNumber">
                <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.phoneNumber")}</Form.Label>
                <Input
                        className={"block h-center-div full-width " + styles.input}
                        type="text"
                        value={phoneNumber}
                        placeholder={translator.decode("users.addUser.placeholder.phoneNumber")}
                        onChange={e => setPhone(e.target.value)}
                        required={true}
                        />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="email">
                <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.email")}</Form.Label>
                <Input
                        className={"block h-center-div full-width " + styles.input}
                        type="text"
                        value={email}
                        placeholder={translator.decode("users.addUser.placeholder.email")}
                        onChange={e => setEmail(e.target.value)}
                        required={true}
                        />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="prefLang">
                <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.lang")}</Form.Label>
                <Select
                        className={"block h-center-div full-width " + styles.input}
                        options={langOptions}
                        styles={customStyles}
                        placeholder={translator.decode("users.addUser.placeholder.lang")}
                        value={preferedLang}
                        onChange={e => { setPreferedLang(e)}}
                        blurInputOnSelect={true}
                        controlShouldRenderValue={true}
                        required={true}
                        defaultValue={role}
                        />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="role">
                <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.role")}</Form.Label>
                <Select
                        className={"block h-center-div full-width " + styles.input}
                        options={roleOptions}
                        styles={customStyles}
                        placeholder={translator.decode("users.addUser.placeholder.role")}
                        value={role}
                        onChange={e => { setRole(e)}}
                        blurInputOnSelect={true}
                        controlShouldRenderValue={true}
                        required={true}
                        defaultValue={role}
                        />
            </Form.Group>

            <Form.Group className={styles.inputGroup} controlId="commercialOpt">
                <Form.Label className={styles.colorEmphasis}>{translator.decode("users.addUser.placeholder.commercial")}</Form.Label>
                <Select className={"block h-center-div full-width " + styles.input} styles={customStyles}
                    name="commercial" onChange={e => { setCommercialOpt(e) }}
                    value={commercialOpt} options={props.adminsOptions}
                    blurInputOnSelect={true} controlShouldRenderValue={true}
                    required={true} defaultValue={commercialOpt} />
            </Form.Group>

        </Form>
        {error ? <p style={{color: "red"}}>{errorMessage}</p> : null}
    </MyModal>
    );
}

export default AddUser;