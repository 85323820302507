import React from 'react';
import {CSSTransition} from 'react-transition-group';
import ReactDOM from 'react-dom';
import translator from "MultiLanguage/TranslationWrapper";
import Button from "Theme/Component/Button";
import MyModal from 'Content/Modal/MyModal'

import styles from "Content/Modal/ValidOffer.module.css";

const validOfferModal = props => {
  // return (
    function validationFooter() {
      return (
        <>
          <Button onClick={props.onReset} className="button">
            {translator.decode("validOffer.reset")}
          </Button>
        </>
      )
    }

    return (
      <MyModal
        show={props.show}
        footer={validationFooter()}
        title={props.title} className={styles.ValidOfferModal}>
          <p>{translator.decode("validOffer.content")}</p>
      </MyModal>
    )
};

export default validOfferModal;