/*
 * File: Pictures.js
 * Project: front
 * File Created: Tuesday, 26th March 2024 11:49:44
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 29th March 2024 03:56:17
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import styles from "Content/Car/Car.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import CarrousselPicturesModal from 'Content/Modal/CarrousselPictures';
import {useState, useEffect} from 'react';

function Pictures(props) {
    const rowIdx = props.rowIdx;
    const selectedElementIdx = props.selectedElementIdx;
    const [pictures, setPictures] = useState(props.pictures)
    // const [offer, setOffer] = useState(props.offer);
    const [showCaroussel, setShowCaroussel] = useState(false);
    const [frontPicture, setFrontPicture] = useState("");

    useEffect(() => {
        let frontPic = pictures.find((e) => e.name?.startsWith("front"))?.URI ?? "";
        if (frontPic == "" && pictures.length > 0)
            frontPic = pictures[0].URI;
        setFrontPicture(frontPic);
    }, [])

    return (
        <>
        <td onClick={(e) => e.stopPropagation()} key={`line_pictures_${rowIdx}`} className={`${styles.centered} ${styles.min} ${(rowIdx === selectedElementIdx ? "selected": "")}`}>
                <img alt="" src={frontPicture} width={"50px"} height={"50px"} onClick={(e) => setShowCaroussel(true)}/>
        </td>
        <CarrousselPicturesModal className={`${styles.centered}`} onClick={(e) => e.stopPropagation()} onClose={(e) => setShowCaroussel(false)} pictures={props.pictures} show={showCaroussel} title={`${translator.decode("offers.pictures.carousselTitle")}${props.carID}`} />
        </>);
}

export default Pictures;
