import Axios from "axios";
import Config from "Config/Config";
import ServiceGeneral from "Services/ServiceGeneral";
import UserSession from "Utils/UserSession";
import util from 'util';
import AuthenticationError from "Error/AuthenticationError";

class AutobizService {

    static async getIntelFromReg(reg) {
        var tokens = await UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }

        try {
            const response = await Axios.get(Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_INTEL_FROM_REG + "/" + reg, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async getBrands() {
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }

        try {
            const response = await Axios.get(Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_BRANDS, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async getModels(brandId) {
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }

        try {
            const response = await Axios.get(Config.BACKEND.BASEURL + util.format(Config.BACKEND.PATHS.GET_MODELS, brandId), data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async getIntels(brandId, modelId, year) {
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }

        try {
            const response = await Axios.get(Config.BACKEND.BASEURL + util.format(Config.BACKEND.PATHS.GET_INTELS, brandId, modelId, year), data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

}

export default AutobizService;