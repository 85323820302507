/*
 * File: Button.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */
import styled from "styled-components";

/**
* Styled component Button
*/
const Button = styled.button.attrs(props => ({
    variant: props.variant,
    className: "font-weight-900 extra-lineheight-1 medium-font-2 " + props.className,
}))`
padding-right: 10px;
padding-left: 10px;
border-radius: 5px;
border: 0;
color: ${props => props.variant === "positive" ? props.theme.colors.fifth : (props.variant === "negative" ? props.theme.colors.fifth : props.theme.colors.fifth)};
background-color: ${props => props.variant === "positive" ? props.theme.colors.sixth : (props.variant === "negative" ? props.theme.colors.second: (props.variant === "warning" ? props.theme.colors.seventh : (props.variant === "primal" ? props.theme.colors.fourth : props.theme.colors.heighth)))};
&:hover, &:focus {
}
`;

export default Button;
