/*
 * File: RouterPathResolver.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import UserSession from "Utils/UserSession"

/**
 * Differences in routes by roles (To improve by grouping AC declaration by role and declaration by role in this file
 * in the same file in order to have only one place with declaration of different roles)
 */
const ROUTE_BY_ROLES = {
    admin: {
        pathEntryPoint: "/sellsoffers"
    },
    user: {
        pathEntryPoint: "/form"
    },
    default: {
        pathEntryPoint: "/sellsoffers"
    }
}

/**
 * Router description of all the different paths
 */
const RouterPathResolver = {
    // Do not change the next line, configure it via the ROUTE_BY_ROLES above
    pathEntryPoint : () => {return UserSession.getRole() !== undefined ? ROUTE_BY_ROLES[UserSession.getRole()].pathEntryPoint : ROUTE_BY_ROLES.default.pathEntryPoint; },
    routeLogin : { path : "/" },
    routeForm : { path : "/form" },
    routeForgotPassword : { path : "/forgotpassword" },
    routeNewPassword : { path : "/newPassword/:encrypted" },
    routeUser : { path : "/user" },
    routeSellsOffers : { path : "/sellsoffers" },
    routeLegal : { path : "/legal" }
};

export default RouterPathResolver;