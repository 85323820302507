/*
 * File: Price.js
 * Project: front
 * File Created: Tuesday, 26th March 2024 11:48:58
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 14th May 2024 12:51:28
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import {useEffect, useState} from 'react';
import { AiOutlineEuro as Euro, AiOutlineLike as Like, AiOutlineDislike as Dislike } from "react-icons/ai";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import styles from "Content/Car/Car.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import InputSubmit from "Theme/Component/InputSubmit";
import OfferUtils from 'Utils/OfferUtils';
import PropositionModal from 'Content/Modal/PropositionModal';
import CounterPropositionModal from 'Content/Modal/CounterPropositionModal';
import PreviousPrice from './PreviousPrice';
import OfferService from 'Services/Offers/OfferService';

function PriceOffer(price_props) {
    const [tooltipFocused, setTooltipFocused] = useState(false);

    return <OverlayTrigger
    delay={{ hide: 500, show: 250 }}
    trigger={["hover", "focus", "click"]}
    // rootCloseDisabled={true}
    // rootClose={false}
    show={tooltipFocused}
    overlay={(props) => (
    <Tooltip {...props} >
        <PreviousPrice previousOffers={price_props.previousOffers} />
    </Tooltip>
    )}
    placement="auto">
    <div  className={`col-12`} onFocus={() => {console.log("Setting tooltip focus to true"); setTooltipFocused(true)}}  onMouseEnter={() => {console.log("Setting tooltip mouse enter to true"); setTooltipFocused(true)}} onBlur={() => {console.log("Setting tooltip focus to false");setTooltipFocused(false)}}  onMouseLeave={() => {console.log("Setting tooltip mouse leave to false"); setTooltipFocused(false)}}>
        {price_props.lastOffer !== undefined && price_props.lastOffer.price !== null && price_props.lastOffer.price !== undefined ?
            <p className="no-margin w100">{price_props.lastOffer.price} €</p> :
            <p className="no-margin w100">{translator.decode("offers.nothing")}</p>
        }
    </div>
</OverlayTrigger>
}

function Price(props) {
    // const [offer, setOffer] = useState(props.offer);
    const role = props.role;
    const rowIdx = props.rowIdx;
    const [lastOffer, setLastOffer] = useState(props.offer.offers && props.offer.offers.length > 0 ? props.offer.offers[0] : undefined)
    const [isStillAvailable, setIsStillAvailable] = useState(Math.floor((Date.now() - new Date(props.offer.createdAt).getTime()) / (1000 * 60 * 60 * 24)) < 30 && (( props.offer.offers && props.offer.offers.length > 0 && [undefined, null].includes(props.offer.idBee2Link) )  || (props.offer.offers == undefined ||  props.offer.offers.length == 0) ) && props.offer.status == "encours")
    // const isStillAvailable = Math.floor((Date.now() - new Date(props.offer.createdAt).getTime()) / (1000 * 60 * 60 * 24)) < 30 && (( props.offer.offers && props.offer.offers.length > 0 && ![undefined, null].includes(props.offer.idBee2Link) )  || (props.offer.offers == undefined ||  props.offer.offers.length == 0) ) && props.offer.status == "encours";
    const selectedElementIdx = props.selectedElementIdx;
    const [showProposition, setShowProposition] = useState(false);
    const [showCounterProposition, setShowCounterProposition] = useState(false);
    const [previousOffers, setPreviousOffers] = useState([])

    useEffect(() => {
        OfferService.getPreviousOffers(props.offer.id).then((val) => {
            if (val.current) {
                let current = val.current.sort((a, b) => new Date(b.at) - new Date(a.at))[0];
                setLastOffer(current)
                setIsStillAvailable(Math.floor((Date.now() - new Date(props.offer.createdAt).getTime()) / (1000 * 60 * 60 * 24)) < 30 && ((val.current.length > 0 && [undefined, null].includes(props.offer.idBee2Link) ) || val.current.length == 0) && props.offer.status == "encours");
                let filtered = val.current.filter(v => current.at != v.at);
                val.current =  filtered
            }
            setPreviousOffers(val)
        }).catch(() => {
            console.error(`Unable to load prices for user offer ${props.offerCarID}`)
         })
    }, [])

    return (
        <>
            <td key={`line_price_${rowIdx}`} className={`${styles.centered} ${styles.priceCell} ${(rowIdx === selectedElementIdx ? "selected": "")}`}>
                <div className={`container ${styles.row}`}>
                    <div className="row">
                        <PriceOffer previousOffers={previousOffers} lastOffer={lastOffer}/>
                    </div>
                    <div className="row" onClick={e => e.stopPropagation()}>
                        <div  className={`col-12 ${styles.inputButtons}`}>
                    {(isStillAvailable && role === "admin" && (lastOffer==undefined || lastOffer == null || !lastOffer.counterOffer)) ?
                        <span className={styles.iconInputBtn} onClick={(e) => {setShowProposition(true);}} >
                            <Euro className={styles.buyIcons}/>
                            <InputSubmit id={`submit-${rowIdx}`} className={styles.input_submit} value={translator.decode("offers.counterOffer.send")} variant="warning"/>
                        </span> : null
                    }
                    {isStillAvailable && role === "admin" || OfferUtils.isOfferTooOld(props.offer.createdAt) ?
                        <span className={styles.iconInputBtn} onClick={(e) => props.handleRefuse(props.offer)}>
                            <Dislike className={styles.buyIcons}/>
                            <InputSubmit id={`refuse-${rowIdx}`} className={styles.input_submit} value={translator.decode("offers.counterOffer.refuse")} variant="negative"/>
                        </span> : null
                    }
                    { lastOffer !== undefined && lastOffer.price !== null && lastOffer.price !== undefined ?
                        <>
                            { (role === "user" && !lastOffer.counterOffer) && isStillAvailable ?
                                <span className={styles.iconInputBtn} onClick={(e) => {setShowCounterProposition(true)}} >
                                    <Euro className={styles.buyIcons}/>
                                    <InputSubmit id={`counter-${rowIdx}`} onClick={(e) => setShowCounterProposition(true)}
                                        className={styles.input_submit}
                                        value={translator.decode("offers.counterOffer.send")} variant="warning"/>
                                </span> : null
                            }
                            {((role === "user" && !lastOffer.counterOffer) || (role === "admin" && lastOffer.counterOffer)) ?
                                <>
                                { isStillAvailable ?
                                    <span className={styles.iconInputBtn} onClick={(e) => props.handleAccept(props.offer)}>
                                        <Like className={styles.buyIcons}/>
                                        <InputSubmit id={`accept-${rowIdx}`}
                                            className={styles.input_submit}
                                            value={translator.decode("offers.counterOffer.accept")} variant="positive"/>
                                    </span> : null
                                }
                                { props.offer.status == "encours" ?
                                    <span className={styles.iconInputBtn} onClick={(e) => props.handleRefuse(props.offer)}>
                                        <Dislike className={styles.buyIcons}/>
                                        <InputSubmit id={`refuse-${rowIdx}`}
                                            className={styles.input_submit}
                                            value={translator.decode("offers.counterOffer.refuse")} variant="negative"/>
                                    </span> : null
                                }
                                </> : null
                            }
                        </> : null
                        }
                        </div>
                    </div>
                </div>
                {role === "user" && lastOffer !== undefined && lastOffer.price !== null ?
                    <CounterPropositionModal show={showCounterProposition} onClose={() => {setShowCounterProposition(false); props.onCounterClose(); props.handleUpdatePrice();} } offer={{id: props.offer.id, regNb: props.offer.car.regNb, price: lastOffer ? lastOffer.price : 0}}/> : null
                }
                {role === "admin" ?
                    <PropositionModal cb={(val) => setLastOffer(val.offers[0])} show={showProposition} onClose={() => {setShowProposition(false);props.onPropositionClose(); props.handleUpdatePrice();}} offer={{id: props.offer.id, regNb: props.offer.car.regNb, price: lastOffer ? lastOffer.price : 0}} isAnOldCar={false} /> : null
                }
            </td>
        </>);
}

export default Price;
