/*
 * File: AuthenticationException.ts
 * Project: front
 * File Created: Wednesday, 21st December 2022 04:34:48
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 22nd December 2022 09:00:08
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2022 SAS Olivier Dubois, SAS Olivier Dubois
 */

class AuthenticationError extends Error {
    constructor(msg) {
        super(msg);
        Object.setPrototypeOf(this, AuthenticationError.prototype);
    }
}

export default AuthenticationError;