/*
 * File: AccessControl.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import AControl from "accesscontrol";
import AccessControlConfig from "Config/AC/AccessControl.config";
import UserSession from "Utils/UserSession";

const AC = new AControl(AccessControlConfig);

class AccessControl {
    /**
     * Check if the user has the capability to create any for the given field
     * @param {String} field The field on which to operate (defined in the constructor)
     * @returns True if can, false otherwise
     */
    static canCreateAny(field) {
        const role = UserSession.getRole();

        if (!role) {
            return false;
        }
        try {
            const permissions = AC.can(role).createAny(field);
            if (permissions.granted) {
                return true;
            }
            return false;
        } catch(err) {
            return false;
        }
    }

    /**
     * Check if the user has the capability to update any for the given field
     * @param {String} field The field on which to operate (defined in the constructor)
     * @returns True if can, false otherwise
     */
    static canUpdateAny(field) {
        const role = UserSession.getRole();

        if (!role) {
            return false;
        }
        try {
            const permissions = AC.can(role).updateAny(field);
            if (permissions.granted) {
                return true;
            }
            return false;
        } catch(err) {
            return false;
        }
    }

    /**
     * Check if the user has the capability to delete any for the given field
     * @param {String} field The field on which to operate (defined in the constructor)
     * @returns True if can, false otherwise
     */
    static canDeleteAny(field) {
        const role = UserSession.getRole();

        if (!role) {
            return false;
        }
        try {
            const permissions = AC.can(role).deleteAny(field);
            if (permissions.granted) {
                return true;
            }
            return false;
        } catch(err) {
            return false;
        }
    }

    /**
     * Check if the user has the capability to read any for the given field
     * @param {String} field The field on which to operate (defined in the constructor)
     * @returns True if can, false otherwise
     */
    static canReadAny(field) {
        const role = UserSession.getRole();

        if (!role) {
            return false;
        }
        try {
            const permissions = AC.can(role).readAny(field);
            if (permissions.granted) {
                return true;
            }
            return false;
        } catch(err) {
            return false;
        }
    }
}

export default AccessControl;
