/*
 * File: ResetUserPassword.js
 * Project: front
 * File Created: Tuesday, 30th May 2023 05:53:26
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 31st May 2023 09:35:02
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import React from 'react';
import {CSSTransition} from 'react-transition-group';
import ReactDOM from 'react-dom';
import UserService from "Services/Users/UserService";
import translator from "MultiLanguage/TranslationWrapper";
import Button from "Theme/Component/Button";
import MyModal from 'Content/Modal/MyModal'

import styles from "Content/Modal/ResetUserPassword.module.css";

const ResetUserPassword = props => {
    const HandleSubmit = (event) => {
      event.preventDefault();
      UserService.forgottenPassword(props.email);
      props.close();
  }

  function validationFooter() {
      return (
        <>
          <Button id={"noButton"} onClick={props.close} className={`button ${styles.validationButton} ${styles.refuse}`}>
            {translator.decode("dynamic.no").toUpperCase()}
          </Button>
          <Button id={"yesButton"} onClick={HandleSubmit} className={`button ${styles.validationButton} ${styles.accept}`}>
            {translator.decode("dynamic.yes").toUpperCase()}
          </Button>
        </>
      )
    }

    return (
      <MyModal
        show={props.show}
        footer={validationFooter()}
        title={props.title} className={styles.ValidOfferModal} footerClassName={styles.footer}>
      </MyModal>
    )
};

export default ResetUserPassword;