/*
 * File: Guards.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import RouterPathResolver from "Config/Router/RouterPathResolver";
import UserSession from "Utils/UserSession";
import AccessControl from "Guards/AccessControl";

const OPERATIONS = {
    "delete" : AccessControl.canDeleteAny,
    "create" : AccessControl.canCreateAny,
    "read" : AccessControl.canReadAny,
    "update" : AccessControl.canUpdateAny
};

/**
 * Middleware used to check if user is already logged in or not
 * @param {Object} to The object of the guard containing props passed to
 * @param {Object} from Information from the next navigation position
 * @param {*} next The next function object as usual middleware
 */
function isLoggedInMiddleware(to, from, next) {
    if (to.meta.src === "login") {
        if (isLoggedIn()) {
            return next.redirect(RouterPathResolver.pathEntryPoint());
        }
    } else if (!isLoggedIn()) {
        return next.redirect(RouterPathResolver.routeLogin.path);
    }
    return next();
}

/**
 * Middleware used to check if user can do an operation. Middleware login MUST be called before,
 * otherwise an undefined behavior could happened
 * @param {Object} to The object of the guard containing props passed to
 * @param {Object} from Information from the next navigation position
 * @param {*} next The next function object as usual middleware
 */
function canDoMiddleware(to, from, next) {
    if (to.meta.role !== undefined && to.meta.role.value === true) {
        const field = to.meta.role.field;
        const operations = to.meta.role.operations;

        if (canDo(operations, field))
            next();
        else {
            if (from.location.pathname === RouterPathResolver.pathEntryPoint()) {
                return next();
            }
            return next.redirect(RouterPathResolver.pathEntryPoint());
        }
    } else {
        next();
    }
}

/**
 * Check if the user is currently logged in
 * @returns True if logged, false otherwise
 */
function isLoggedIn() {
    return UserSession.isUserLoggedIn();
}

/**
 * This function returns true or false if the current logged in user can do the given operations
 * @param {Array} operations The operations the user want to do
 * @param {String} field The field (unique key for the operations) the user want to operate on
 * @returns True if all the operations can be done, False otherwise
 */
function canDo(operations, field) {
    var canDo = true;

    for (var operation in operations) {
        if (operations[operation](field) === false)  {
            canDo = false;
            break;
        }
    }
    return canDo;
}

export { isLoggedInMiddleware, isLoggedIn, canDo, canDoMiddleware, OPERATIONS};