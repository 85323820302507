import Axios from "axios";
import Config from "Config/Config";
import ServiceGeneral from "Services/ServiceGeneral";
import UserSession from "Utils/UserSession";
import AuthenticationError from "Error/AuthenticationError";

class OfferService {
    static buildConfig() {
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }
        return data;
    }

    static async getOffers(hidden = false) {
        var tokens = await UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }

        try {
            const response = await Axios.get(`${Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_OFFERS}?hidden=${hidden}`, data);
            return response.data;
        } catch(e) {
            const data2 = ServiceGeneral.interpretError(e);
            if (await ServiceGeneral.handleAxiosResponse(e) === "end") {
                return data2;
            }
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }
    }

    static async sendOffer(offer) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }
        try {
            const response = Axios.post(Config.BACKEND.BASEURL + Config.BACKEND.PATHS.ADD_OFFER, offer, config);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async updateOffer(offerId, offer, isAnOldOffer) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }

        // try {
        var response;
        if (isAnOldOffer)
            response = await Axios.put(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_CARS +  "/" + offerId, offer, config);
        else
            response = await Axios.put(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_OFFERS +  "/" + offerId, offer, config);
        if (ServiceGeneral.handleAxiosResponse(response) === "end") {
            return undefined;
        }
        return response.data;
        // } catch(e) {
        //     if (e instanceof AuthenticationError)
        //         throw e;
        //     const data = ServiceGeneral.interpretError(e);
        //     return data;
        // }
    }

    static async submitOffer(offerId, offer, isAnOldOffer) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }

        try {
            var response;
            if (isAnOldOffer)
                response = await Axios.post(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_CARS +  "/" + offerId, offer, config);
            else
                response = await Axios.post(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_OFFERS +  "/" + offerId, offer, config);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response.data;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }
    }

    static async validateOffer(offerId) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }

        try {
            const response = Axios.get(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.ADD_OFFER +  "/" + offerId + Config.BACKEND.PATHS.VALIDATE_OFFER , config);
            if (ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async propagateOfferProposition(offerId) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }

        try {
            const response = Axios.get(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_OFFERS +  "/" + offerId + Config.BACKEND.PATHS.PROPAGATE_OFFER_TO_USER , config);
            if (ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }
    }

    static async propagateOfferStatus(offerId, isAnOldOffer) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }

        try {
            if (isAnOldOffer)
                var response = await Axios.get(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_CARS +  "/" + offerId + Config.BACKEND.PATHS.PROPAGATE_STATUS , config);
            else
                var response = await Axios.get(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_OFFERS +  "/" + offerId + Config.BACKEND.PATHS.PROPAGATE_STATUS , config);
            if (ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response.data;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async sendCounterOfferMail(offerId) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }

        try {
            const response = Axios.get(Config.BACKEND.BASEURL+ Config.BACKEND.PATHS.GET_OFFERS +  "/" + offerId + Config.BACKEND.PATHS.COUNTER_OFFER, config);
            if (ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }
    }

    static transformValue(elem) {
        if (elem === true || elem === false)
            if (elem === true)
                return "OUI";
            else
                return "NON";
        try {
            const found = elem.toString().match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/g);
            if (found !== null) {
                var date = new Date(elem);
                return date.toLocaleDateString("fr-FR")
            }
        } catch (e) {
            console.log(elem + " is not a date");
        }
        return elem;
    };

    static async hideOffers() {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }
        try {
            let res = await Axios.get(`${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_OFFERS}/hide`, config);
            return res.data;
        } catch (e) {
            console.error(`Unable to hide offers: ${e.message}`);
            return null;
        }
    };

    static DownloadOffers(onError) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }
        Axios.get(`${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.EXTRACT_OFFERS}`, {...config, responseType: 'arraybuffer'})
        .then((response) => {
            return {blob: new Blob([response.data]), dispo: response.headers["content-disposition"], type: response.headers["content-type"]};
        }).then((response) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([response.blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.type = response.type;
            let filename = response.dispo.split("=")[1];
            link.setAttribute(
                'download',
                filename.replaceAll("\"", ""),
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }).catch((e) => {
            onError();
        });
    }

    static async getPreviousOffers(userOfferID) {
        let config = OfferService.buildConfig();

        try {
            const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_OFFERS}/${userOfferID}${Config.BACKEND.PATHS.GET_OFFERS}`;
            const response = await Axios.get(url, config);
            // if (ServiceGeneral.handleAxiosResponse(response) === "end") {
            //     return undefined;
            // }
            return await response.data;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }
    }
}

export default OfferService;
