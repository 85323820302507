/*
 * File: Translation.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import Config from "Config/Config";

const ERROR_MESSAGE_NOT_FOUND = "Invalid Key (not found).";

/**
* Class to translate content for display
*/
class Translator {
    /**
    * Constructor of the object that setups default language
    */
    constructor() {
        this.actualLanguage = Config.COMMON.LANGUAGES[0];
        this.changeJsonUsed(this.actualLanguage);
    }

    /**
    * Changes the language in use
    * @param {String} language The new language
    * @throws Error
    */
    setLanguage(language) {
        if (language === this.actualLanguage) {
            return;
        } else if (!Config.COMMON.LANGUAGES.includes(language)) {
            throw new Error("Language: Language not supported or invalid file");
        }
        this.actualLanguage = language;
        this.changeJsonUsed(this.actualLanguage);
    }

    getLanguage() {
        return this.actualLanguage;
    }

    /**
    * Changes the language. If the file do not exist, throws an error.
    * @param {String} language The new language to fetch
    * @throws Throws if the given language file does not exist
    */
    changeJsonUsed(language) {
        try {
            this.json = require("../Config/Translation/" + this.actualLanguage + ".json");
        } catch {
            throw new Error("Error, could not load this Language json file");
        }
    }

    /**
    * Get the available languages
    * @returns The arry of languages
    */
    getAvailableLanguages() {
        return Config.COMMON.LANGUAGES;
    }

    /**
    * Get the string in the urrent language corresponding to the given position string
    * @param {string} position The String representing the position of the object with dot notation
    * @throws Throws if the path is not valid
    */
    decode(position) {
        const array = position.split('.');

        try {
            return this.getJson(array, 0, this.json);
        } catch  {
            console.error("Unable to translate: " + position)
        };
        return position;
    }

    /**
    *
    * @param {*} array The tree to look for
    * @param {*} idx The actual index in the array
    * @param {*} elem The actual element node
    */
    getJson(array, idx, elem) {
        if (idx === array.length - 1) {
            return elem[array[idx]];
        }
        return this.getJson(array, idx + 1, elem[array[idx]]);
    }
}

export default Translator;