/*
 * File: Car.js
 * Project: front
 * File Created: Tuesday, 26th March 2024 11:19:09
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 11th April 2024 03:05:16
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */
import {BsSignStop} from 'react-icons/bs';
import {VscDebugStart} from 'react-icons/vsc';
import { useHistory } from "react-router-dom";

import {HEADERS, ADMINHEADERS} from "Hooks/HookOffers";
import styles from "Content/Car/Car.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import AuthenticationError from "Error/AuthenticationError";
import Price from 'Content/Price/Price';
import Pictures from 'Content/Pictures/Pictures';
import OfferUtils from 'Utils/OfferUtils';
import OldPrice from 'Content/Price/OldPrice';

function LegacyCar(props) {
    const history = useHistory();

    const handleAccept = (cb, offer) => {
        try {
            OfferUtils.handleAccept(offer, true);
        } catch (e) {
            if (e instanceof AuthenticationError)
                history.push(`/login?return_url=${e.getLocation()}`)
            else
                throw e;
        }
        cb(true);
    }

    const handleRefuse = (cb, offer) => {
        try {
            OfferUtils.handleRefuse(offer, true);
        } catch (e) {
            if (e instanceof AuthenticationError)
                history.push(`/login?return_url=${e.getLocation()}`)
            else
                throw e;
        }
        cb(true);
    }

    const transformValue = (elem) => {
        if (elem == null || elem == undefined)
            return ""
        if (elem === true || elem === false)
            if (elem === true)
                return translator.decode("dynamic.yes").toUpperCase();
            else
                return translator.decode("dynamic.no").toUpperCase();
        try {
            const found = elem.toString().match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/g);
            if (found !== null) {
                var date = new Date(elem);
                return date.toLocaleDateString("fr-FR")
            }
        } catch (e) {
            console.log(elem + " is not a date");
        }
        return elem !== null && elem !== undefined ? elem : "";
    };

    return (
        Object.keys(HEADERS).filter((elem) => {return elem !== "authorId"}).map((elem, idx) => {
            var ret = null;
            if (!Object.keys(ADMINHEADERS).includes(elem)) {
                switch (elem) {
                    case "status":
                        ret = (<td key={`lc_${elem}_${idx}_${props.index}`} className={`${styles.centered} ${styles.min} ${(props.index === props.selectedElementIdx ? "selected": "")} ${(props.car[elem] === "accepted" ? styles.accepted : (props.car[elem] === "discarded" ? styles.refused : (props.car[elem] === "cancel" ? styles.cancel : styles.pending)))}`}>
                                {/* <p className={`${styles.minimalParaph} no-margin`}>  */}
                                {translator.decode(`offers.status.${props.car[elem]}`)}
                                {props.car.idBee2Link ? (props.car.availableForRemoval ? <VscDebugStart color={'green'} size={"3rem"} /> : <BsSignStop color={'red'} size={"3rem"}/>) : ""}
                                {/*</p> */}
                            </td>);
                        break;
                    case "price":
                        ret = <OldPrice
                            offer={props.car}
                            onPropositionClose={() => props.setShowProposition(false)}
                            onPropositionClick={() => props.setShowProposition(true)}
                            showProposition={props.showProposition}
                            onCounterClose={() => props.setShowCounterProposition(false)}
                            onCounterPropositionClick={() => props.setShowCounterProposition(true)}
                            showCounter={props.showCounterProposition}
                            rowIdx={props.carNumber}
                            handleAccept={handleAccept.bind(null, props.cb)} handleRefuse={handleRefuse.bind(null, props.cb)}
                            handleUpdatePrice={props.handleUpdatePrice}
                            role={props.role}
                            selectedElementIdx={props.selectedElementIdx} />
                        break;
                    case "version":
                    case "energy":
                    case "comment":
                        ret = (<td key={`lc_${elem}_${idx}_${props.index}`} className={`${styles.centered} ${styles.ellipsis} ${(props.index === props.selectedElementIdx ? "selected": "")}`}>
                            {/* <p className={`${styles.minimalParaph} no-margin`}>  */}
                            {props.car[elem].toString()}
                            {/* </p> */}
                        </td>);
                        break;
                    case "pictures":
                        ret = <Pictures key={`lc_${elem}_${idx}_${props.index}`} pictures={props.car.pictures} rowIdx={props.carNumber} selectedElementIdx={props.selectedElementIdx} carID={props.car.id} />;
                        break;
                    case "bee2linkStatut":
                        ret = (<td key={`lc_${elem}_${idx}_${props.index}`} className={`${styles.centered} ${styles.ellipsis} ${(props.index === props.selectedElementIdx ? "selected": "")}`}>
                            {/* <p className={`${styles.minimalParaph} no-margin`}>  */}
                            {props.car.availableForRemoval ? <VscDebugStart color={'green'} /> : <BsSignStop color={'red'} />}
                            {/* </p> */}
                        </td>);
                        break;
                    default:
                        ret = (<td key={`lc_${elem}_${idx}_${props.index}`} className={`${styles.centered} ${styles.min} ${(props.index === props.selectedElementIdx ? "selected": "")}`}>
                                {/* <p className={`${styles.minimalParaph} no-margin`}>  */}
                                {transformValue(props.car[elem]).toString()}
                                {/*</p> */}
                            </td>);
                        break;

                }
                return ret;
            }
            else if (props.role === "admin") {
                // console.log(`current element: ${elem}`)
                switch (elem) {
                    case "company":
                        ret = (<td key={`lc_${elem}_${idx}_${props.index}`} className={`${styles.centered} ${styles.min} ${(props.index === props.selectedElementIdx ? "selected": "")}`}>
                                {/* <p className={`${styles.minimalParaph} no-margin`}>  */}
                                {props.car.idBee2Link ? <img src="/logos/app4you.png" alt="image" style={{width: "4rem"}} /> : null}
                                {transformValue(props.car.author ? props.car.author[elem] : "ERROR").toString()}
                                {/*</p> */}
                            </td>);
                            break;
                    default:
                        ret = (<td key={`lc_${elem}_${idx}_${props.index}`} className={`${styles.centered} ${styles.min} ${(props.index === props.selectedElementIdx ? "selected": "")}`}>
                        {transformValue(props.car.author ? props.car.author[elem] : "ERROR").toString()}
                        </td>)
                        break
                }
                return ret;
                }
            })
        )
}

export default LegacyCar;
