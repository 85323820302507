import React from "react";
import Alert from "react-bootstrap/Alert";
import {useEffect, useState, useContext} from "react";
import Form from 'react-bootstrap/Form';
import { useHistory, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import RouterPathResolver from "Config/Router/RouterPathResolver";

import translator from "MultiLanguage/TranslationWrapper";

import InputSubmit from "Theme/Component/InputSubmit";
import Input from "Theme/Component/Input";
import Select from "Theme/Component/Select";

import styles from "Content/Formulaire/Form.module.css";
import Picture from "Content/Formulaire/Picture/Picture";
import ValidOffer from "Content/Modal/ValidOffer";
import CameraButton from "Content/Formulaire/CameraButton/CameraButton";
import PicturesContainer from "Content/Formulaire/PicturesContainer/PicturesContainer";

import UserService from 'Services/Users/UserService';
import AutobizService from 'Services/Autobiz/AutobizService';
import PictureService from "Services/Pictures/PictureService";
import OfferService from "Services/Offers/OfferService";
import AuthenticationError from "Error/AuthenticationError";
import Compressor from "compressorjs";

import { Context, useLanguageContext } from "Utils/Context";

const PictureMemo = React.memo(Picture);

function OfferForm(props) {
    const user = UserService.getMe();
    const [ authorId, setAuthorId ] = useState(0);
    const [ firstname, setFirstName ] = useState("");
    const [ lastname, setLastName ] = useState("");
    const [ company, setCompany ] = useState("");
    const [ adress, setAdress ] = useState("");
    const [ adressComp, setAdressComp ] = useState("");
    const [ city, setCity ] = useState("");
    const [ postalCode, setPostalCode ] = useState("");
    const [ country, setCountry ] = useState("");
    const [ phoneNumber, setPhone ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ regNb, setRegNb ] = useState("");
    const [ genre, setGenre ] = useState("");
    const [ color, setColor ] = useState("");
    const [ brand, setBrand ] = useState({});
    const [ brandOptions, setBrandOptions ] = useState([]);
    const [ model, setModel ] = useState({});
    const [ modelOptions, setModelOptions ] = useState([]);
    const [ mec, setMEC ] = useState({});
    const [ energy, setEnergy ] = useState({});
    const [ energyOptions, setEnergyOptions ] = useState([]);
    const [ gears, setGears ] = useState({});
    const [ gearsOptions, setGearsOptions ] = useState([]);
    const [ versions, setVersions ] = useState({});
    const [ versionOptions, setVersionOptions ] = useState([]);
    const [ location, setLocation ] = useState("");
    const [ km, setKm ] = useState(1);
    const [ dpr, setDpr ] = useState({});
    const [ keys, setKeys ] = useState({"value": false, "label": translator.decode("dynamic.no").toUpperCase()});
    const [ maintain, setMaintain ] = useState({"value": false, "label": translator.decode("dynamic.no").toUpperCase()});
    const [ motor, setMotor ] = useState({"value": false, "label": translator.decode("dynamic.no").toUpperCase()});
    const [ trial, setTrial ] = useState({"value": false, "label": translator.decode("dynamic.no").toUpperCase()});
    const [ tva, setTva ] = useState();
    const [ frontPic, setFrontPic] = useState(null);
    const [ backPic, setBackPic] = useState(null);
    const [ interiorPic, setInteriorPic] = useState(null);
    const [ speedoPic, setSpeedoPic] = useState(null);
    const [ morePic, _setMorePic] = useState([])
    const [ comment, setComment ] = useState("");
    const [ regError, setRegistrationError ] = useState(false);
    const [ showModal, setShowModal ] = useState(false);
    const [ waitSpinner, setWaitSpinner ] = useState(false);
    const [ waitSendSpinner, setWaitSendSpinner ] = useState(false);
    const [ forceReload, setForceReload ] = useState(0);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ submitError, setSubmitError ] = useState(false);
    const language = useLanguageContext();
    const currentLocation = useLocation();
    const history = useHistory();
    const test = useContext(Context);
    console.log("Using context value in form: ");
    console.log(language);
    console.log("Using test context value in form: ");
    console.log( test);

    const setMorePic = (idx, picture) => {
        if (idx > morePic.length) {
            while (idx < morePic.length)
                morePic.push(null);
            _setMorePic([...morePic])
            return setMorePic(idx, picture);
        }
        if (idx === morePic.length)
            return _setMorePic([...morePic, picture]);
        morePic[idx] = picture;
        return _setMorePic([...morePic]);
    }

    const boolOptions = [{"value": true, "label":translator.decode("dynamic.yes").toUpperCase()}, {"value": false, "label":translator.decode("dynamic.no").toUpperCase()}];
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'gray' : 'default',
          borderBottom: state.isFocused ? '2px solid red' : '0px transparent',
          borderLeft: state.isFocused ? '2px solid red' : '0px transparent'
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            padding: 0,
            display: "flex",
        }),
          singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';

          return { ...provided, opacity, transition };
        }
      }

    useEffect(() => {
        setForceReload(forceReload + 1);
    }, [language["language"]]);

    useEffect(() => {
            user.then(userData => {
                setAuthorId(userData.id)
                setFirstName(userData.firstname);
                setLastName(userData.lastname);
                setCompany(userData.company);
                setAdress(userData.address);
                setAdressComp(userData.addressComp);
                setPostalCode(userData.codePostal);
                setCity(userData.city);
                setCountry(userData.country);
                setPhone(userData.phoneNumber);
                setEmail(userData.email);
        });
    }, [user, frontPic]);

    const onSetDpr = (event) => {
        const newDate = new Date(event.target.value)
        setDpr(newDate)
    }

    const setValues = (val, setVal, setValOptions) => {
        var tmp = []
        if (Array.isArray(val)) {
            for (var valId in val){
                const value = val[valId]
                tmp.push({"value": value.name, "label": value.name});
            }
            setVal(tmp[0]);
            setValOptions(tmp);
        } else if (val.message === undefined) {
            setVal({"value": val.name, "label": val.name});
            setValOptions([{"value": val.name, "label": val.name}]);
        }
    }

    const onSetMEC = (event) => {
        const newDate = new Date(event.target.value)
        setMEC(newDate)
        if (regError) {
            let intelsResp;
            try {
                intelsResp = AutobizService.getIntels(brand.id, model.id, newDate.getFullYear());
            } catch (e) {
                if (e instanceof AuthenticationError)
                    history.push(`/login?return_url=${e.getLocation()}`)
                else
                    throw e;
            }
            intelsResp.then(data => {
                setValues(data.data.versions, setVersions, setVersionOptions)
                setValues(data.data.fuels, setEnergy, setEnergyOptions)
                setValues(data.data.gears, setGears, setGearsOptions)
            }).catch(async (e) => {
                if (e instanceof AuthenticationError) {
                    var ret = await UserService.refreshTokens()
                    if (ret === false)
                        history.push(`/?return_url=${location.pathname}`);
                    else {
                        onSetMEC(event);
                    }
                }
            });
            const myVec2 = document.getElementById("vehicule-data2");
            var tmp = myVec2.attributes.getNamedItem("hidden")
            if (tmp !== null && tmp !== undefined)
                myVec2.attributes.removeNamedItem("hidden");
        }
    }

    const onSetBrand = (value) => {
        setBrand(value);
        let modelResp;
        try {
            modelResp = AutobizService.getModels(value.id);
        } catch (e) {
            if (e instanceof AuthenticationError)
                history.push(`/login?return_url=${e.getLocation()}`)
            else
                throw e;
        }
        modelResp.then(data => {
            var tmpModels = []
            for (var modelIdx in data.data){
                const model = data.data[modelIdx]
                tmpModels.push({"value": model.name, "label": model.name, "id": model.id});
            }
            setModel(tmpModels[0]);
            setModelOptions(tmpModels);
        }).catch(async (e) => {
            if (e instanceof AuthenticationError) {
                var ret = await UserService.refreshTokens()
                if (ret === false)
                    history.push(`/?return_url=${location.pathname}`);
                else {
                    onSetBrand(value);
                }
            }
        });
    }

    const handleImmatOnBlur = (event) => {
        if (event.target.value === "")
            return;
        let resp = AutobizService.getIntelFromReg(event.target.value);
        // const resp = fakeValidData()
        setWaitSpinner(true);
        resp.then(data => {
            if (data.status === 200 && data.data.message === undefined && data.data.status === true) {
                setRegistrationError(false);
                const myVec1 = document.getElementById("vehicule-data1");
                var tmp = myVec1.attributes.getNamedItem("hidden")
                if (tmp !== null && tmp !== undefined)
                    myVec1.attributes.removeNamedItem("hidden");
                const myVec2 = document.getElementById("vehicule-data2");
                tmp = myVec2.attributes.getNamedItem("hidden")
                if (tmp !== null && tmp !== undefined)
                    myVec2.attributes.removeNamedItem("hidden");
                setBrand({"value": data.data.makeName, "label": data.data.makeName, "id": data.data.makeId});
                setBrandOptions([{"value": data.data.makeName, "label": data.data.makeName, "id": data.data.makeId}]);
                setModel({"value": data.data.modelName, "label": data.data.modelName, "id": data.data.modelId});
                setModelOptions([{"value": data.data.modelName, "label": data.data.modelName, "id": data.data.modelId}]);
                setGenre(data.data.bodyName.toUpperCase())
                setColor(data.data.color.toUpperCase())
                const releaseDateArray = data.data.dateRelease.split("-");
                var releaseDate = new Date(parseInt(releaseDateArray[0]), parseInt(releaseDateArray[1]) - 1, parseInt(releaseDateArray[2]) + 1)
                setMEC(releaseDate);
                setEnergy({"value": data.data.fuelName, "label": data.data.fuelName});
                setEnergyOptions([{"value": data.data.fuelName, "label": data.data.fuelName}])
                if (data.data.gearboxName !== null && data.data.gearboxName !== undefined && data.data.gearboxName.length > 0) {
                    setGears({"value": data.data.gearboxName, "label": data.data.gearboxName});
                    setGearsOptions([{"value": data.data.gearboxName, "label": data.data.gearboxName}])
                } else {
                    const intelsResp = AutobizService.getIntels(data.data.makeId, data.data.modelId, releaseDate.getFullYear());
                    intelsResp.then(data => {
                        setValues(data.data.gears, setGears, setGearsOptions)
                    }).catch(async (e) => {
                        if (e instanceof AuthenticationError) {
                            var ret = await UserService.refreshTokens()
                            if (ret === false)
                                history.push(`/?return_url=${location.pathname}`);
                            else {
                                handleImmatOnBlur(event);
                            }
                        }
                    });
                }
                var tmpVersions = []
                if (data.data.versions.length > 0) {
                    for (var verId in data.data.versions){
                        const ver = data.data.versions[verId]
                        tmpVersions.push({"value": ver.name, "label": ver.name});
                    }
                    setVersions(tmpVersions[0]);
                    setVersionOptions(tmpVersions);
                }
                else {
                    let intelsResp = AutobizService.getIntels(data.data.makeId, data.data.modelId, releaseDate.getFullYear());
                    intelsResp.then(data => {
                        setValues(data.data.versions, setVersions, setVersionOptions)
                    }).catch(async (e) => {
                        if (e instanceof AuthenticationError) {
                            var ret = await UserService.refreshTokens()
                            if (ret === false)
                                history.push(`/?return_url=${location.pathname}`);
                            else {
                                handleImmatOnBlur(event);
                            }
                        }
                    });
                }
            } else {
                setRegistrationError(true);
                let brandResp = AutobizService.getBrands();
                brandResp.then(async (data) => {
                    var tmpBrands = []
                    for (var brandIdx in data.data){
                        const brand = data.data[brandIdx]
                        tmpBrands.push({"value": brand.name, "label": brand.name, "id": brand.id});
                    }
                    setBrand(tmpBrands[0]);
                    setBrandOptions(tmpBrands);
                    let modelResp;
                    try {
                        modelResp = await AutobizService.getModels(tmpBrands[0].id);
                    } catch (e) {
                        if (e instanceof AuthenticationError) {
                            var ret = await UserService.refreshTokens();
                            if (ret === false)
                                history.push(`/?return_url=${location.pathname}`);
                            else {
                                handleImmatOnBlur(event);
                            }
                        }
                    }
                    var tmpModels = []
                    for (var modelIdx in modelResp.data){
                        const model = modelResp.data[modelIdx]
                        tmpModels.push({"value": model.name, "label": model.name, "id": model.id});
                    }
                    setModel(tmpModels[0]);
                    setModelOptions(tmpModels);
                }).catch(async (e) => {
                    if (e instanceof AuthenticationError) {
                        var ret = await UserService.refreshTokens()
                        if (ret === false)
                            history.push(`/?return_url=${location.pathname}`);
                        else {
                            handleImmatOnBlur(event);
                        }
                    }
                });
                const myVec1 = document.getElementById("vehicule-data1");
                var tmpVec = myVec1.attributes.getNamedItem("hidden")
                if (tmpVec !== null && tmpVec !== undefined)
                myVec1.attributes.removeNamedItem("hidden");
            }
            setWaitSpinner(false);
        }).catch(async (e) => {
            if (e instanceof AuthenticationError) {
                var ret = await UserService.refreshTokens()
                if (ret === false)
                    history.push(`/?return_url=${location.pathname}`);
                else {
                    handleImmatOnBlur(event);
                }
            }
        });
    }

    const userData = () => {
        return (
            <>
            <h4 className={styles.sectionTitle + " " + styles.colorEmphasis}>{translator.decode("form.title.contact")}</h4>

            <div className={`${styles.myRow}`}>
                <div className={`${styles.myCol} ${styles.centered} ${styles.groupLine}`}>
                    <Form.Group className={styles.inputGroup} controlId="lastname">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.lastname")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={lastname}
                            placeholder={translator.decode("form.placeholder.lastname")}
                            onChange={e => setLastName(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>

                    <Form.Group className={styles.inputGroup} controlId="firstname">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.firstname")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={firstname}
                            placeholder={translator.decode("form.placeholder.firstname")}
                            onChange={e => setFirstName(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>
                </div>

                <div className={`${styles.myCol} ${styles.centered} ${styles.groupLine}`}>
                    <Form.Group className={styles.inputGroup} controlId="company">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.company")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={company}
                            placeholder={translator.decode("form.placeholder.company")}
                            required={true}
                            onChange={e => setCompany(e.target.value)}
                            disabled />
                    </Form.Group>
                </div>
            </div>

            <div className={`${styles.myRow}`}>
                <div className={`${styles.myCol} ${styles.centered} ${styles.groupLine}`}>
                    <Form.Group className={styles.inputGroup} controlId="adress">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.address")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={adress}
                            placeholder={translator.decode("form.placeholder.address")}
                            onChange={e => setAdress(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>

                    <Form.Group className={styles.inputGroup} controlId="adressComp">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.addressComp")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={adressComp}
                            placeholder={translator.decode("form.placeholder.addressComp")}
                            onChange={e => setAdressComp(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>
                </div>

                <div className={`${styles.myCol} ${styles.centered} ${styles.groupLine}`}>
                    <Form.Group className={styles.inputGroup} controlId="postal_code">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.postal_code")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={postalCode}
                            placeholder={translator.decode("form.placeholder.postal_code")}
                            onChange={e => setPostalCode(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>

                    <Form.Group className={styles.inputGroup} controlId="city">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.city")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={city}
                            placeholder={translator.decode("form.placeholder.city")}
                            onChange={e => setCity(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>

                    <Form.Group className={styles.inputGroup} controlId="country">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.country")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={country}
                            placeholder={translator.decode("form.placeholder.country")}
                            onChange={e => setCountry(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>
                </div>
            </div>


            <div className={`${styles.myRow}`}>
                <div className={`${styles.myCol} ${styles.centered} ${styles.groupLine}`}>
                    <Form.Group className={styles.inputGroup} controlId="phone">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.phoneNumber")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={phoneNumber}
                            placeholder={translator.decode("form.placeholder.phoneNumber")}
                            onChange={e => setPhone(e.target.value)}
                            required={true}
                            disabled
                            />
                    </Form.Group>
                </div>

                <div className={`${styles.myCol} ${styles.centered} ${styles.groupLine}`}>
                    <Form.Group className={styles.inputGroup} controlId="email">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.email")}</Form.Label>
                    <Input
                            className={`block h-center-div full-width ${styles.input}`}
                            type="text"
                            value={email}
                            placeholder={translator.decode("form.placeholder.email")}
                            onChange={e => setEmail(e.target.value)}
                            required={true}
                            disabled />
                    </Form.Group>
                </div>
            </div>
        </>
        )
    }

    const vehicleData = () => {
        return (
            <>
                <h4 className={`${styles.sectionTitle} ${styles.colorEmphasis}`}>{translator.decode("form.title.vehicle")}</h4>

                <div className={`${styles.myRow}`}>
                    <div className={`col-11 ${styles.centered } ${styles.groupLine}`}>
                        <Form.Group className=  {styles.inputGroup} controlId="immatriculation">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.registration")}</Form.Label>
                        <Input
                                className={"block h-center-div full-width " + styles.input}
                                type="text"
                                value={regNb}
                                placeholder={translator.decode("form.placeholder.registration")}
                                onBlur={handleImmatOnBlur}
                                onChange={e => setRegNb(e.target.value)}
                                required={true}
                                />
                        </Form.Group>
                        {
                            waitSpinner &&
                            <div id={styles.divBackground} className={`v-center-content h-center-content`}>
                                <Spinner animation="border" id={styles.spinner}/>
                            </div>
                        }
                    </div>
                        {regError &&
                            <div className={`col-11 ${styles.centered } ${styles.groupLine}`}>
                                <Alert variant="danger" className="margined block">
                                    {translator.decode("form.error_messages.reg_error")}
                                </Alert>
                            </div>
                        }
                </div>
                <br/>
                <div className={`${styles.myRow}`}  id="vehicule-data1" hidden={true}>
                    <div className={`col-11 ${styles.centered } ${styles.groupLine}`}>
                        <Form.Group className={styles.inputGroup} controlId="brand">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.brand")}</Form.Label>
                        <Select
                                className={"block h-center-div full-width " + styles.input}
                                value={brand}
                                options={brandOptions}
                                styles={customStyles}
                                placeholder={translator.decode("form.placeholder.brand")}
                                onChange={onSetBrand}
                                required={true}
                                />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="model">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.model")}</Form.Label>
                        <Select
                                className={"block h-center-div full-width " + styles.input}
                                value={model}
                                options={modelOptions}
                                placeholder={translator.decode("form.placeholder.model")}
                                styles={customStyles}
                                onChange={e => setModel(e)}

                                required={true}
                                />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="mec">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.MEC")}</Form.Label>
                        <Input
                                className={"block h-center-div full-width " + styles.input}
                                type="date"
                                value={mec.toISOString !== undefined ? mec.toISOString().split("T")[0] : ""}
                                styles={customStyles}
                                placeholder={translator.decode("form.placeholder.MEC")}
                                onChange={onSetMEC}
                                required={true}
                                />
                        </Form.Group>

                    </div>
                </div>

                <div className={`${styles.myRow}`}  id="vehicule-data2" hidden={true}>
                    <div className={`col-11 ${styles.centered} ${styles.groupLine}`}>
                        <Form.Group className={styles.inputGroup} controlId="versions">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.versions")}</Form.Label>
                        <Select
                                className={"block h-center-div full-width " + styles.input}
                                value={versions}
                                options={versionOptions}
                                styles={customStyles}
                                placeholder={translator.decode("form.placeholder.versions")}
                                onChange={e => setVersions(e)}
                                required={true}
                                />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="energy">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.energy")}</Form.Label>
                        <Select
                                className={"block h-center-div full-width " + styles.input}
                                options={energyOptions}
                                value={energy}
                                styles={customStyles}
                                placeholder={translator.decode("form.placeholder.energy")}
                                onChange={e => setEnergy(e)}
                                required={true}
                                />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="gears">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.gears")}</Form.Label>
                        <Select
                                className={"block h-center-div full-width " + styles.input}
                                type="text"
                                options={gearsOptions}
                                value={gears}
                                styles={customStyles}
                                placeholder={translator.decode("form.placeholder.gears")}
                                onChange={e => setGears(e)}
                                required={true}
                                />
                        </Form.Group>

                    </div>
                </div>
                <br/>

                <div className={`${styles.myRow}`}>
                    <div className={`${styles.myCol} ${styles.centered } ${styles.groupLine}`}>
                        <Form.Group className={styles.inputGroup} controlId="km">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.Km")}</Form.Label>
                        <Input
                                className={"block h-center-div full-width " + styles.input}
                                type="number"
                                value={km}
                                min={1}
                                placeholder={translator.decode("form.placeholder.Km")}
                                onChange={e => setKm(e.target.value)}
                                required={true}
                                 />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="location">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.location")}</Form.Label>
                        <Input
                                className={"block h-center-div full-width " + styles.input}
                                type="text"
                                value={location}
                                placeholder={translator.decode("form.placeholder.location")}
                                onChange={e => setLocation(e.target.value)}
                                required={true}
                                 />
                        </Form.Group>
                    </div>

                    <div className={`${styles.myCol} ${styles.centered } ${styles.groupLine}`}>
                    <Form.Group className={styles.inputGroup} controlId="dpr">
                        <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.dpr")}</Form.Label>
                        <Input
                                className={"block h-center-div full-width " + styles.input}
                                type="date"
                                value={dpr.toISOString !== undefined ? dpr.toISOString().split("T")[0] : ""}
                                onChange={onSetDpr}
                                placeholder={translator.decode("form.placeholder.dpr")}
                                required={true}
                                />
                        </Form.Group>
                    </div>
                </div>
                <div className={`${styles.myRow}`}>
                    <div className={`${styles.myCol} ${styles.centered} ${styles.groupLine}`}>
                        <Form.Group className={styles.inputGroup} controlId="keys">
                            <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.keys")}</Form.Label>
                            <Select
                                    className={"block h-center-div full-width " + styles.input}
                                    options={boolOptions}
                                    styles={customStyles}
                                    placeholder={translator.decode("form.placeholder.keys")}
                                    value={keys}
                                    onChange={e => { setKeys(e)}}
                                    blurInputOnSelect={true}
                                    controlShouldRenderValue={true}
                                    required={true}
                                    defaultValue={keys}
                                    />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="maintain">
                            <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.maintain")}</Form.Label>
                            <Select
                                    className={"block h-center-div full-width " + styles.input}
                                    options={boolOptions}
                                    styles={customStyles}
                                    placeholder={translator.decode("form.placeholder.maintain")}
                                    value={maintain}
                                    onChange={e => { setMaintain(e)}}
                                    blurInputOnSelect={true}
                                    controlShouldRenderValue={true}
                                    required={true}
                                    defaultValue={maintain}
                                    />
                        </Form.Group>
                    </div>

                    <div className={`${styles.myCol} ${styles.centered } ${styles.groupLine}`}>
                        <Form.Group className={styles.inputGroup} controlId="trial">
                            <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.trial")}</Form.Label>
                            <Select
                                    className={"block h-center-div full-width " + styles.input}
                                    options={boolOptions}
                                    styles={customStyles}
                                    placeholder={translator.decode("form.placeholder.trial")}
                                    value={trial}
                                    onChange={e => { setTrial(e)}}
                                    blurInputOnSelect={true}
                                    controlShouldRenderValue={true}
                                    required={true}
                                    defaultValue={trial}
                                    />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="motor">
                            <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.motor")}</Form.Label>
                            <Select
                                    className={"block h-center-div full-width " + styles.input}
                                    options={boolOptions}
                                    styles={customStyles}
                                    placeholder={translator.decode("form.placeholder.motor")}
                                    value={motor}
                                    onChange={e => { setMotor(e)}}
                                    blurInputOnSelect={true}
                                    controlShouldRenderValue={true}
                                    required={true}
                                    defaultValue={motor}
                                    />
                        </Form.Group>

                        <Form.Group className={styles.inputGroup} controlId="TVA">
                            <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.TVA")}</Form.Label>
                            <Select
                                    className={"block h-center-div full-width " + styles.input}
                                    options={boolOptions}
                                    styles={customStyles}
                                    placeholder={translator.decode("form.placeholder.TVA")}
                                    value={tva}
                                    onChange={e => { setTva(e) }}
                                    blurInputOnSelect={true}
                                    controlShouldRenderValue={true}
                                    minLength={3}
                                    required={true}
                                    />
                        </Form.Group>
                    </div>
                </div>
            </>
        )};

    const pictures = () => {
        return (
            <>
                <h4 className={styles.sectionTitle + " " + styles.colorEmphasis }>{translator.decode("form.title.picture")}</h4>
                <PicturesContainer >
                    <Picture key={frontPic != null ? frontPic.toString() : ""} controlId="picFront" inputId="take-picFront" file={{pic: frontPic}} className="" onChange={(event) => {setFrontPic(event.target.files[0])}} placeholder={translator.decode("form.placeholder.pictures.front")} required={true} />
                    <Picture controlId="picBack" inputId="take-picBack" className="" onChange={(event) => setBackPic(event.target.files[0])} placeholder={translator.decode("form.placeholder.pictures.back")} required={true} />
                </PicturesContainer>
                <PicturesContainer >
                    <Picture controlId="picSpeedometer" inputId="take-picSpeed" file={speedoPic} className="" onChange={(event) => {setSpeedoPic(event.target.files[0]); return "Coucou";}} placeholder={translator.decode("form.placeholder.pictures.console")} required={true} />
                    <Picture controlId="picInterior" inputId="take-picInterior" file={interiorPic} className="" onChange={(event) => setInteriorPic(event.target.files[0])} placeholder={translator.decode("form.placeholder.pictures.interior")} required={true} />
                </PicturesContainer>
                <CameraButton pictures={morePic} setPictures={setMorePic} />
            </>
        )
    }

    const commentary = () => {
        return (
        <div className={`${styles.myRow}`}>
            <div className={`col-11 ${styles.centered } ${styles.groupLine}`}>
                <Form.Group className={styles.inputGroup} controlId="comment">
                    <Form.Label className={styles.colorEmphasis}>{translator.decode("form.placeholder.comment")}</Form.Label>
                    <Input
                        className={"block h-center-div full-width " + styles.inputTextArea}
                        type="textarea"
                        maxLength="512"
                        value={comment}
                        placeholder={translator.decode("form.placeholder.comment")}
                        onChange={e => setComment(e.target.value)}
                    />
                </Form.Group>
            </div>
        </div>
    )}

    const SendForm = async () => {
        setSubmitError(false);
        setWaitSendSpinner(true);

        var offer = {
            "dpr": dpr.toISOString(),
            "immat": regNb,
            "brand": brand.value !== undefined ? brand.value : "",
            "model": model.value !== undefined ? model.value : "",
            "genre": genre,
            "location": location,
            "couleur": color,
            "essai": trial.value,
            "clefs": keys.value,
            "entretien": maintain.value,
            "finition": versions.value !== undefined ? versions.value : "",
            "moteur": motor.value,
            "energie": energy.value !== undefined ? energy.value : "",
            "mec": mec.toISOString(),
            "km": km,
            "tva": tva.value,
            "comment": comment,
            "authorId": authorId,
            "bdv": gears.value !== undefined ? gears.value : ""
        }
        var offerRetResp = OfferService.sendOffer(offer);
        offerRetResp.then(async (offerRet) => {
            let pics = [];
            let compressions = []
            compressions.push(PictureService.uploadPicture(frontPic, offer.immat, "front", offerRet.data.id, pics));
            compressions.push(PictureService.uploadPicture(backPic, offer.immat, "back", offerRet.data.id, pics));
            compressions.push(PictureService.uploadPicture(speedoPic, offer.immat, "speedometer", offerRet.data.id, pics));
            compressions.push(PictureService.uploadPicture(interiorPic, offer.immat, "interior", offerRet.data.id, pics));
            morePic.map(async (elem, index) => {
                compressions.push(PictureService.uploadPicture(elem, offer.immat, "costPic_" + index.toString(), offerRet.data.id, pics));
            });
            await new Promise(r => setTimeout(r, 2000));
            console.log(pics.length);
            await Promise.all(compressions);
            for (var i = 0; i < pics.length; ++i)
                await pics[i];
            console.log(`Pictures length at sending: ${pics.length}`);
            await OfferService.validateOffer(offerRet.data.id);
            setWaitSendSpinner(false);
            setShowModal(true);
        }).catch(async (e) => {
            if (e instanceof AuthenticationError) {
                var ret = await UserService.refreshTokens()
                if (ret === false)
                    history.push(`/?return_url=${location.pathname}`);
                else {
                    SendForm();
                }
            }
        });

    }

    const HandleSubmit =  async (event) => {
        event.preventDefault();
        if (tva === undefined || tva.value === null || tva.value === undefined || tva.value === "") {
            setErrorMessage(translator.decode("form.placeholder.TVA"));
            setSubmitError(true);
            return null;
        }
        if (versions.value === null || versions.value === undefined || versions.value === "")
        {
            setErrorMessage(translator.decode("form.placeholder.versions"));
            setSubmitError(true);
            return null;
        }
        if (gears.value === null || gears.value === undefined || gears.value === "") {
            setErrorMessage(translator.decode("form.placeholder.gears"));
            setSubmitError(true);
            return null;
        }
        await SendForm();
    }

    const HandleReset = () => {
        window.location.reload();
        }

    const HandleContinue = () => {
        history.push(RouterPathResolver.routeSellsOffers.path)
    }

    return(
    <React.Fragment>
        <div id={styles.div_formContainer} className={"container-flex " + styles.scrollable}>
            <Form onSubmit={!waitSendSpinner ? HandleSubmit : null} id={ styles.div_form } className={styles.centered}>
                {userData()}
                {vehicleData()}
                {pictures()}
                {commentary()}
                {
                    waitSendSpinner &&
                    <div id={styles.divBackground} className={`v-center-content h-center-content`}>
                        <Spinner animation="border" id={styles.spinner}/>
                    </div>
                }
                <p style={{"color": "black", "backgroundColor":"red"}} hidden={!submitError}>{translator.decode("form.error_messages.send_error")}+{errorMessage}</p>
                <InputSubmit
                className={"block h-center-div full-width font-weight-700 large-font-2 margin-bottom " + styles.input_submit}
                value={translator.decode("form.send")} disabled={waitSendSpinner} />

            </Form>
        </div>
        <ValidOffer
            onClose={() => setShowModal(false)}
            onReset={() => HandleReset()}
            onContinue={() => HandleContinue()}
            show={showModal}
            title={`${translator.decode("validOffer.title")} ${regError ? brand.value + " " + model.value : "n°" + regNb}`}
        >
            <p>{translator.decode("validOffer.content")}</p>
        </ValidOffer>
    </React.Fragment>)
}

export default OfferForm;