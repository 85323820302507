/*
 * File: OfferHiding.js
 * Project: front
 * File Created: Friday, 22nd December 2023 09:31:06
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 4th January 2024 05:20:12
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import OfferService from "Services/Offers/OfferService";
import translator from "MultiLanguage/TranslationWrapper";
import UserService from 'Services/Users/UserService';
import styles from "Content/Offers/Offers.module.css";

const { default: HideOffers } = require("Content/Modal/HideOffers");
const { useState } = require("react")

function OfferHidding() {
    const [show, setShow] = useState(false);
    const [role, setRole] = useState(UserService.getUserRole());

    const hideOffers = () => {
        OfferService.hideOffers().then(() => {
            setShow(false);
            // window.location.reload();
        })
    }

    return (
        <>
            <button onClick={() => {setShow(!show); console.log(show);}} className={`btn btn-secondary  ${styles.btnHideOffer}`}>
                {translator.decode("dynamic.hideOffer")}
            </button>
            <HideOffers show={show} onReset={() => setShow(false)} onValidation={hideOffers} >
                <p>{translator.decode("hideOffers.validation")}</p>
            </HideOffers>
        </>
    )
}

export default OfferHidding