/*
 * File: Input.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */
import styled from "styled-components";

/**
* Styled component Input
* Type: input (provided via props)
*/
const Input = styled.input.attrs(props => ({
    type: props.type,
    className: "medium_font_2  extra-lineheight-1",
}))`
padding-right: 10px;
padding-left: 10px;
color: ${props => props.variant === "positive" ? props.theme.colors.fifth : (props.variant === "negative" ? props.theme.colors.fifth : props.theme.colors.third)};
background-color: ${props => props.variant === "positive" ? props.theme.colors.sixth : (props.variant === "negative" ? props.theme.colors.second: (props.variant === "warning" ? props.theme.colors.seventh : props.theme.colors.first))};
border-top: 0px;
border-left: 0px;
border-right: 0px;
border-bottom: 1px solid ${props => props.theme.colors.fourth};
&:hover, &:focus {
    outline: none;
    background-color: ${props => props.theme.colors.fifth};
}
`;

export default Input;
