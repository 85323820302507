import Input from "Theme/Component/Input";
import styles from "Content/Formulaire/Form.module.css";
import {useState} from "react";

function ImageUpload(props) {
    const [preview, setPreview] = useState("")

    const onSelectFile = e => {
        props.onChange(e);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }

    return (
        <div className="file-container">
            <p className={"picture-input " + styles.colorEmphasis}>
                Fichier:<Input type="file" id={props.inputId} accept="image/*" onChange={onSelectFile} required={props.required} />
            </p>
            <p id="error"></p>
            {preview !== "" &&  <img src={preview} alt="" className={styles.inputImg}/> }
        </div>
    )
}

export default ImageUpload;