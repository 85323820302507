/*
 * File: AccessControlPathResolver.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

const Paths = {
    FORM : "form",
    CONNEXION: "connexion",
    FORGOTPASSWORD: "forgotpassword",
    USER: "user",
    SELLSOFFERS: "sellsoffers"
}

export default Paths;
