/*
 * File: OfferHiding.js
 * Project: front
 * File Created: Friday, 22nd December 2023 09:31:06
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 4th April 2024 04:38:35
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import OfferService from "Services/Offers/OfferService";
import translator from "MultiLanguage/TranslationWrapper";
import UserService from 'Services/Users/UserService';
import styles from "Content/Offers/Offers.module.css";

const { default: RevealOffers } = require("Content/Modal/RevealOffers");
const { useState } = require("react")

function OfferDiscover(props) {
    const [show, setShow] = useState(false);

    const discoverOffers = () => {
        OfferService.getOffers(true).then((data) => {
            setShow(false);
            if (props.cb)
                props.cb(data.data ? data.data : [])
        })
    }

    return (
        <>
            <button onClick={() => {setShow(!show); console.log(show);}} className={`btn btn-secondary  ${styles.btnHideOffer}`}>
                {translator.decode("discoverOffer.reveal")}
            </button>
            <RevealOffers show={show} onReset={() => setShow(false)} onValidation={discoverOffers} >
                <p>{translator.decode("discoverOffer.validation")}</p>
            </RevealOffers>
        </>
    )
}

export default OfferDiscover