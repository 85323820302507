import React from "react";
import translator from "MultiLanguage/TranslationWrapper";
import { useHistory, useLocation } from "react-router-dom";
import {HEADERS, ADMINHEADERS, useFiltersSorting} from "Hooks/HookOffers";
import Table from 'react-bootstrap/Table';
import { FiChevronDown, FiChevronRight, FiChevronUp } from "react-icons/fi";
import styles from "Content/Offers/Offers.module.css";
import {useEffect, useState, useRef, useMemo} from "react";
import OfferService from "Services/Offers/OfferService";
import UserService from 'Services/Users/UserService';
import AuthenticationError from "Error/AuthenticationError";
import OfferHidding from "./OfferHidding";
import OfferUtils from "Utils/OfferUtils";
import OfferDiscover from "./OfferDiscover";
import Car from "../Car/Car";
import LegacyCar from "../Car/LegacyCar";

function Offers(props) {
    const history = useHistory();
    const filteringOptions= {
        "all": (offers) => offers,
        "archived": OfferUtils.filterByStatus.bind(null, ["discarded", "cancel"]),
        "accepted": OfferUtils.filterByStatus.bind(null, ["accepted"]),
        "encours": OfferUtils.filterByStatus.bind(null, ["encours"]),
    }
    const [curStatus, setCurStatus] = useState("all")
    const sort = useFiltersSorting();
    const [originalOffers, setOriginalOffers] = useState([]);
    const [offers, setOffers] = useState([]);
    const [updatedOffersList, setUpdatedOffersList] = useState(false);
    const [selectedElementIdx, setSelectedElementIdx] = useState(-1);
    const [role, setRole] = useState(UserService.getUserRole());
    const [showProposition, setShowProposition] = useState(false);
    const [showCounterProposition, setShowCounterProposition] = useState(false);
    const location = useLocation();

    useEffect(() => {
        console.log(curStatus)
        console.log(Object.keys(filteringOptions))
        if (Object.keys(filteringOptions).includes(curStatus))
            setOffers(filteringOptions[curStatus](originalOffers));
    }, [curStatus])

    useEffect(() => {
        setRole(UserService.getUserRole());
        let data = OfferService.getOffers();
        setUpdatedOffersList(false);
        data.then(myOffers => {
            let receivedOffers = myOffers.sort((a, b) => {return new Date(b.createdAt ?? b.createDate).valueOf() - new Date(a.createdAt ?? a.createDate).valueOf() })
            // myOffers = myOffers.sort((a, b) => {return new Date(b.createdAt ?? b.createDate).valueOf() - new Date(a.createdAt ?? a.createDate).valueOf() })
            setOriginalOffers(structuredClone(receivedOffers));
            // receivedOffers.reverse();
            setOffers(filteringOptions[curStatus](receivedOffers));
        }).catch(async (e) => {
            if (e instanceof AuthenticationError) {
                var ret = await UserService.refreshTokens()
                if (ret === false)
                    history.push(`/?return_url=${location.pathname}`);
                else {
                    setUpdatedOffersList(true);
                }
                    // window.location.reload(false);
            }
            else
                throw e;
        })
    }, [updatedOffersList, showProposition, showCounterProposition])

    useEffect(() => {
        if (["company", "firstname", "lastname", "phoneNumber"].includes(sort.sort.value)) {
            offers.sort((a, b) => {
                let aAttr = a.author[sort.sort.value];
                let bAttr = b.author[sort.sort.value];
                return aAttr.localeCompare(bAttr);
            })
        } else {
            console.log(sort.sort)
            if (sort.sort.value == "")
                sort.sort.value = "brand"
            console.log(sort.sort)
            offers.sort((a, b) => {
                if (["carID", "createDate", "brand", "color", "bdv", "comment", "energy", "genre", "regNb", "version"].includes(sort.sort.value)) {
                    if (sort.sort.value != "createDate") {
                        var aAttr = a.createdAt ? a.car[sort.sort.value] : a[sort.sort.value]
                        var bAttr = b.createdAt ? b.car[sort.sort.value] : b[sort.sort.value]
                    } else {
                        var aAttr = a.createdAt ?? a.car.createDate;
                        var bAttr = b.createdAt ?? b.car.createDate;
                    }
                } else {
                    var aAttr = a[sort.sort.value]
                    var bAttr = b[sort.sort.value]
                }
                let ret = 0;
                switch (typeof aAttr) {
                    case 'string':
                        const found1 = aAttr.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/g);
                        const found2 = bAttr.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/g);
                        if (found1 !== null && found2 !== null) {
                            var date1 = new Date(aAttr);
                            var date2 = new Date(bAttr);
                            ret = date1.valueOf() - date2.valueOf();
                     } else
                            ret = aAttr.localeCompare(bAttr);
                        break;
                    case 'number':
                        ret = aAttr - bAttr;
                        break;
                    case "boolean":
                        let aAttrBIS = aAttr ? 1 : 0;
                        let bAttrBIS = bAttr ? 1 : 0;
                        ret = aAttrBIS - bAttrBIS;
                        break;
                    default:
                        ret = 0;
                        break;
                }
                return ret;
            });
        }
        switch (sort.order.value % 3){
            case 2:
                setOffers(filteringOptions[curStatus](structuredClone(offers)));
                break;
            case 1:
                setOffers(filteringOptions[curStatus](structuredClone(offers.reverse())));
                break;
            default:
                setOffers(filteringOptions[curStatus](structuredClone(originalOffers.reverse())));
                break;
        }
    }, [sort.sort.value, sort.order.value])

    const handleUpdatePrice = () => {
        setUpdatedOffersList(true);
    }

    const buildHeader = (elem, idx) => {
        var ret = null;
        switch(elem) {
            case "dpr":
            case "regNb":
            case "createDate":
            case "tva":
            case "comment":
            case "price":
                ret =  (
                    <th key={`header_${elem}_${idx}`} className={`${styles.ellipsis}`}
                    onClick={(e) => {
                        if (sort.sort.value !== elem) {
                            sort.sort.set(elem);
                            sort.order.set(1);
                        } else {
                            sort.order.set(sort.order.value + 1)
                        }
                    }} >
                    {/* <p className={`${styles.minimalParaph} no-margin`}> */}
                        {translator.decode("dynamic." + elem)}
                    {/* </p> */}
                    {
                        sort.sort.value === elem ?
                        (sort.order.value % 3 === 2 ? <FiChevronUp key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/> : (sort.order.value % 3 === 1 ? <FiChevronDown key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/> : <FiChevronRight key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/>)) :
                        <FiChevronRight key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/>
                    }
                </th>)
                break;
            default:
                ret = (
                    <th key={`header_${elem}_${idx}`} className={`${styles.min}`}
                    onClick={(e) => {
                        if (sort.sort.value !== elem) {
                            sort.sort.set(elem);
                            sort.order.set(1);
                        } else {
                            sort.order.set(sort.order.value + 1)
                        }
                    }} >
                    {/* <p className={`${styles.minimalParaph} no-margin`}> */}
                        {translator.decode("dynamic." + elem)}
                    {/* </p> */}
                    {
                        sort.sort.value === elem ?
                        (sort.order.value % 3 === 2 ? <FiChevronUp key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/> : (sort.order.value % 3 === 1 ? <FiChevronDown key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/> : <FiChevronRight key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/>)) :
                        <FiChevronRight key={`header_chevron_${elem}_${idx}`} className="flex-halign-right"/>
                    }
                </th>)
                break;
        }
        return ret;
    }

    const getHeadersTable = () => {
        return Object.keys(HEADERS).map((elem, idx) => {
                if (role !== "admin" && !(Object.keys(ADMINHEADERS).includes(elem))) {
                    return buildHeader(elem, idx);
                } else if (role === "admin") {
                    return buildHeader(elem, idx);
                }
                return <></>
            }
        )
    };

    const getParentElem = (elem, id) => {
        if (elem.parentElement === null)
            return null;
        if (elem.parentElement.id.startsWith(id))
            return elem.parentElement
        return getParentElem(elem.parentElement, id)
    }

    const getContentTable = () => {
        var rows = [];
        var index = 0;
        for (var row in offers) {
            // console.log(offers[row]);
            rows.push(
                    <tr key={`row_${row}_${index}`} className={index === selectedElementIdx ? styles.selected: ""} id={`offers_${row}`} >
                        {offers[row].createdAt ?
                            <Car index={index} key={`Car-${index}`} nbCar={row} car={offers[row]} selectedElementIdx={selectedElementIdx} role={role} setShowProposition={setShowProposition} showProposition={showProposition} setShowCounterProposition={setShowCounterProposition} showCounterProposition={showCounterProposition} handleUpdatePrice={handleUpdatePrice} cb={setUpdatedOffersList} />
                            :
                            <LegacyCar index={index} key={`LegacyCar-${index}`} nbCar={row} car={offers[row]} selectedElementIdx={selectedElementIdx} role={role} setShowProposition={setShowProposition} showProposition={showProposition} setShowCounterProposition={setShowCounterProposition} showCounterProposition={showCounterProposition} handleUpdatePrice={handleUpdatePrice}  cb={setUpdatedOffersList} />
                        }
                    </tr>
            );
            index++;
        }
        return rows;
    };
    const handleDownloadOffers = (e) => {
        OfferService.DownloadOffers(() => {
            // setIsOkay(false);
            // setOpenModal(true);
        });
    };

    const shownOffers = useMemo(() => {
        return getContentTable()
    }, [offers])


    useEffect(() => {
        console.log(shownOffers)
    }, [shownOffers])

    return(
        <div className={styles.offerPage}>
            {
                role === "admin" ?
                <>
                    <button onClick={handleDownloadOffers} className={`btn btn-primary ${styles.btnExportExcel}`}>{translator.decode("dynamic.export")}</button>
                    <div className="row m-1">
                        <button onClick={() => setCurStatus("all")} className={`btn btn-${curStatus == "all" ? "" : "outline-"}primary m-1`}>{translator.decode("offers.filteringOptions.all")}</button>
                        <button onClick={() => setCurStatus("accepted")} className={`btn btn-${curStatus == "accepted" ? "" : "outline-"}success m-1`}>{translator.decode("offers.filteringOptions.accepted")}</button>
                        <button onClick={() => setCurStatus("encours")} className={`btn btn-${curStatus == "encours" ? "" : "outline-"}warning m-1`}>{translator.decode("offers.filteringOptions.encours")}</button>
                        <button onClick={() => setCurStatus("archived")} className={`btn btn-${curStatus == "archived" ? "" : "outline-"}danger m-1`}>{translator.decode("offers.filteringOptions.archived")}</button>
                    </div>
                    {/* <OfferHidding /> */}
                </>
                :null
            }
            <div id={styles.div_tableContainer} className={"container-flex " + styles.scrollable}>
                <div className={"row "}>
                    <div className={"col-md-12 " + styles.centered}>
                        <Table striped hover className={"fit-content " + styles.offerTable}>
                            <thead>
                                <tr>
                                    {getHeadersTable()}
                                </tr>
                            </thead>
                            <tbody>
                                {shownOffers}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            {/* {
                role === "admin" ?
                    <OfferDiscover cb={(data) => setOffers(filteringOptions[curStatus]([...offers, ...data]))} />
                    : null
            } */}
        </div>
    );
};

export default Offers;