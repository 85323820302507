/*
 * File: .js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import ThemeConf from "Config/Theme/Theme.light";
import Async from "react-async";

/**
 * Generates the html var corresponding to the theme so they can be automaticaly accessible in CSS
 * To access it use: var(--root-{path}-{in}-{json}) and it will give you the corresponding value.
 * @param {Object} elems The actual object or a string if we are at the last node
 * @param {String} treePos The position in the tree as a string
 * @param {String} elem The current key of the previous object
 */
function generateVarCSS(elems, treePos, elem) {
    var myStr = "";

    if (elem !== "" && typeof elems === "string") {
        return "--" + treePos + ": " + elems + "; ";
    }
    for (var single in elems) {
        myStr += generateVarCSS(elems[single], treePos + "-" + single, single);
    }
    return myStr;
}

/**
 * This function loads the font to the html body
 */
async function loadFonts() {
    var fonts = ThemeConf.fonts;

    for (var elem in fonts) {
        var font = new FontFace(fonts[elem].name, "url(" + fonts[elem].url + ")");
        await font.load().then(function(loaded_font) {
            document.fonts.add(loaded_font);
        }).catch(function(error) {
            console.log("Could not load font.");
        });
    }
}

/**
 * An object to make all theme var accessible in CSS
 */
const GlobalStyles = createGlobalStyle`
    html {
        ${generateVarCSS(ThemeConf, "root", "")}
    }
`;

/**
 * Wrap the theme provider and the global Styles (html predefined variables) to be accessible anywhere in the program
 * Must be added directly after root
 * @param {React.Node} param0 The children components
 */
function Theme(props) {

    return (
    <Async promiseFn={loadFonts}>
    {({ data, error, isPending }) => {
        if (isPending && props.pendingComponent !== undefined) {
            return (
            <>
                <GlobalStyles/>
                    <ThemeProvider theme={ThemeConf}>
                        {props.pendingComponent()};
                    </ThemeProvider>
            </>);
        }
        else {
            return (
            <>
                <GlobalStyles/>
                    <ThemeProvider theme={ThemeConf}>
                        {props.children}
                    </ThemeProvider>
            </>);
        }
    }}
    </Async>)
};

export default Theme;
