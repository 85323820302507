/*
 * File: index.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React from "react";
import ReactDOM from "react-dom";

import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
// Import for to the Global THEME
import Theme from "Theme/Theme";
import "Theme/Theme.css";
// Import for the router
import Router from "Router/Router.js";
// Import for css bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "index.css";

/**
 * Rendering the firsts nodes building the app to the react DOM
 */
ReactDOM.render(
    <React.StrictMode>
        <Theme pendingComponent={LoadingScreen}>
            <Router>
            </Router>
        </Theme>
    </React.StrictMode>,
    document.getElementById('root')
);
