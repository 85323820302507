/*
 * File: Users.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React from "react";

import {HEADERS, useFiltersSorting} from "Hooks/HookUsers";
import translator from "MultiLanguage/TranslationWrapper";
import styles from "Content/Users/Users.module.css";
import Table from 'react-bootstrap/Table';
import AddUserModal from "Content/Modal/AddUserModal";
import Button from "Theme/Component/Button";
import UserService from 'Services/Users/UserService';
import {useEffect, useState, useRef} from "react";
import AuthenticationError from "Error/AuthenticationError";
import { useHistory, useLocation } from "react-router-dom";
import UsersList from "./UsersList";

/**
 * The User component
 * @param {Object} props The propos passed to the hook
 */
function Users(props) {
    const sort = useFiltersSorting();
    const [users, setUsers] = useState([]);
    const admins = useRef([]);
    const [adminsOptions, setAdminsOptions] = useState([]);
    const [adminOptions, setAdminOptions] = useState([]);
    const [addUser, setAddUser] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [selectedElementIdx, setSelectedElementIdx] = useState(-1);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let data = UserService.getUsers();
        data.then(myUsers => {
            setUsers(myUsers);
            console.log(myUsers);
            admins.current = myUsers.filter(user => user.role === "admin");
            console.log(admins.current);
            let optDict = [...admins.current.map((elem) => {return {value: elem.id, label: `${elem.firstname} ${elem.lastname}`}})]
            console.log(optDict)
            setAdminsOptions(optDict)
            // setAdminOptions(optDict);
            console.log(admins);
        }).catch(async (e) => {
            if (e instanceof AuthenticationError) {
                var ret = await UserService.refreshTokens()
                if (ret === false)
                    history.push(`/?return_url=${location.pathname}`);
                else {
                    setUpdated(true);
                }
            }
        });
        setUpdated(false)
    }, [addUser, updated, history, location.pathname])

    const getHeadersTable = () => {
        return Object.keys(HEADERS).map((elem, idx) => {
            return (
            <th key={"header_elem_" + idx}
            onClick={(e) => {
                sort.order.set(sort.order.value + 1)
                if (sort.sort.value !== elem)
                    sort.sort.set(elem)
            }} className="flex">
                <p className="no-margin fit-content inline">
                    {translator.decode("dynamic." + elem)}
                </p>
            </th>)
            }
        )
    };

    const getParentElem = (elem, id) => {
        if (elem.parentElement === null)
            return null;
        if (elem.parentElement.id.startsWith(id))
            return elem.parentElement
        return getParentElem(elem.parentElement, id)
    }
    const getParentNb = (parent) => {
        var pParent = parent.parentElement;
        for (var i = 0; i < pParent.children.length; ++i)
            if (parent === pParent.children[i]) {
                setSelectedElementIdx(i);
                return;
            }
    }

    const handleSelection = (e) => {
        var parent = null;
        if (e.target.id !== "users")
            parent = getParentElem(e.target, "users");
        else
            parent = e.target;
        console.log(e);
        getParentNb(parent)
    }

    const ActionButtons = () => {
        return (
            <div className={`flex-basis-full fit-content margin-top-auto flex-halign-center flex-container ${styles.button_container}`}>
                <Button className={"margin-bottom margin-right " + styles.button} variant="positive" onClick={()=>setAddUser(true)}>{translator.decode("users.button_add")}</Button>
                <Button className={"margin-bottom margin-left " + styles.button} variant="negative" onClick={async ()=>{if (selectedElementIdx !== -1 && selectedElementIdx !== undefined && selectedElementIdx < users.length) { UserService.deleteUser(users[selectedElementIdx].id).then(_ => {
                    setSelectedElementIdx(-1);
                    setUpdated(true);
                }).catch(async (e) => {
                    if (e instanceof AuthenticationError) {
                        var ret = await UserService.refreshTokens()
                        if (ret === false)
                            history.push(`/?return_url=${location.pathname}`);
                        else {
                            UserService.deleteUser(users[selectedElementIdx].id);
                            setSelectedElementIdx(-1);
                            setUpdated(true);
                        }
                    }
                });
                }}}>{translator.decode("users.button_delete")}</Button>
            </div>
        );
    }

    const handleUpdateUser = (row, event) => {
        let usr = users[row];
        console.log(event)
        if (event.target.nodeName === "SELECT")
            usr.commercialID = parseInt(event.target.value);
        else if (event.target.nodeName === "INPUT") {
            let elem = event.target.attributes["elem"];
            console.log(elem.value)
            console.log(event.target.attributes["elem"].value)
            usr = {...usr, ...{[elem.value]: event.target.value}}
        }
        UserService.updateUser(usr);
    }

    const getContentTable = () => {
        var rows = [];
        let elements = [];
        var index = 0;
        for (var row in users) {
            elements = Object.keys(HEADERS).filter((elem) => {return elem !== "id" && elem !== "commercial";}).map((elem, idx) =>
                (
                    <td key={"line_" + elem + "_" + idx} className={index === selectedElementIdx ? styles.selected: ""}>
                            <p className="no-margin">{users[row][elem]}</p>
                    </td>)
                )
            if (adminsOptions.current.length > 0 && adminsOptions.current[0] != undefined && users[row]["role"] == "user") {
                if (users[row]["commercialID"] == -1 || users[row]["commercialID"] == null)
                    elements.push(
                        <td key={"line_commercial_" + row} className={index === selectedElementIdx ? styles.selected: ""}>
                            <select name="commercial" onChange={handleUpdateUser.bind(null, row)}>
                                {[<option value={-1} key="defaultKey">{translator.decode("dynamic.choose_admin")}</option>,...adminOptions]}
                            </select>
                        </td>
                    )
                else {
                    elements.push(
                        <td key={"line_commercial_" + row} className={index === selectedElementIdx ? styles.selected: ""}>
                            <select name="commercial" onChange={handleUpdateUser.bind(null, row)} value={users[row]["commercialID"]}>
                                {
                                    [...adminsOptions.current.map((elem) =>
                                        <option value={elem.value} key={`admin${elem.value}`}>{elem.label}</option>)]
                                }
                            </select>
                        </td>
                    )
                }
            }
            rows.push(
                <tr id={`users${row}`} key={`$row_${row}`} onClick={(e) => handleSelection(e)}>
                    {elements}
                </tr>
            );
            index++;
        }
        return rows;
    };

    return(
        <React.Fragment>
            <div id={styles.div_tableContainer} className={`flex-grow flex-container overflow-auto flex-direction-row flex-basis-full flex-wrap ${styles.centered}`}>
                <ActionButtons />
                <Table striped bordered hover className="margined fit-content">
                    <thead>
                        <tr>
                            {getHeadersTable()}
                        </tr>
                    </thead>
                    <tbody>
                        <UsersList users={users} adminsOptions={adminsOptions} selectedElementIdx={selectedElementIdx} handleUpdateUser={handleUpdateUser} handleSelection={handleSelection} />
                        {/* {getContentTable()} */}
                    </tbody>
                </Table>
                <ActionButtons />
                <AddUserModal show={addUser} adminOptions={adminOptions}  adminsOptions={adminsOptions} onClose={() => setAddUser(false)}/>
            </div>
        </React.Fragment>
    );
};

export default Users;