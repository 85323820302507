/*
 * File: Theme.light.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

/**
 * Config of the light theme
 */
const ThemeConf = {
    colors : {
        first: "#F5F5F5",
        second: "#FF0000",
        third: "#505050",
        fourth: "#d9534f",
        fifth: "#FFFFFF",
        sixth: "#00ff00",
        seventh: "#fcd703",
        heighth: "#252c45"
    },
    logos: {
        logo_horizontal : "/logos/logo.png",
    },
    fonts: {
        text: {
            name: "ABeezee",
            url: "/fonts/ABeeZee.ttf"
        }
    },
    images: {
        france : "/images/france.png"
    }
};

export default ThemeConf;
