/*
 * File: UserService.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import Axios from "axios";
import Config from "Config/Config";
import ServiceGeneral from "Services/ServiceGeneral";
import UserSession from "Utils/UserSession";
import translator from "MultiLanguage/TranslationWrapper";
import AuthenticationError from "Error/AuthenticationError";
import qs from 'qs';

/**
 * Manage user operations with the backend
 */
class UserService {
    /**
     * Login a user with the given credentials
     * @param {String} username The username
     * @param {String} password The password
     * @returns Promise with info of tokens if login successful
     * @throws Throws if an error happened when reaching back
     */
    static async login(username, password) {
        const url = Config.BACKEND.BASEURL + Config.BACKEND.PATHS.LOGIN;
        const params =  qs.stringify({
            grant_type : "password",
            username : username,
            password : password
        });

        try {
            // Set the tokens in cookies for future auth through middleware
            var response = await Axios.post(url, params);
            if (response.status !== 200)
                return response;

            response.data.expire_at = Date.now() + response.data.expires_in * 1000;
            UserSession.setTokens({access_token: response.data.token, refresh_token: response.data.refreshToken});
            UserSession.setRole(response.data.role);
            UserSession.setId(response.data.user_id);
            // Set user information in cookie such as role for auth middleware
            const responseMe = await UserService.getUser(response.data.user_id);

            if (responseMe.status !== 200 && responseMe.status !== 304)
                return responseMe;

            UserSession.setUser(responseMe.data);
            translator.setLanguage(responseMe.data.preferedLanguage);
            // Return logins information (tokens)
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    /**
     * Get the informations about me
     * @returns The user informations
     */
    static async getUser(me) {
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }

        try {
            const response = await Axios.get(Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_USER + "/" + me, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async getMe() {
        var user = UserSession.getUser();
        if (user === undefined)
            return this.getUser(user.id);
        return user;
    }

    static async getUsers() {
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }

        try {
            const response = await Axios.get(Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_USER, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response.data;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);

            return data;
        }
    }

    static async addUser(user) {
        const url = Config.BACKEND.BASEURL + Config.BACKEND.PATHS.ADD_USER;
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }
        try {
            const response = await Axios.post(url, user, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }

    }

    static async updateUser(user) {
        const url = Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_USER + "/" + user.id;
        var tokens = UserSession.getAccessToken();
        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }
        try {
            const response = await Axios.put(url, user, config);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }

    }

    static async deleteUser(userId) {
        const url = Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_USER + "/" + userId;
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }
        try {
            const response = await Axios.delete(url, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }

    }

    static async setNewPassword(password, encrypted) {
        const url = Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_USER + "/new_password/" + encrypted;
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }
        try {
            const response = await Axios.post(url, {password: password}, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }

    }

    static async forgottenPassword(userMail) {
        const url = Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_USER + "/password/" + userMail;
        var tokens = UserSession.getAccessToken();
        var data = {
            headers : {}
        }

        if (tokens !== undefined) {
            data.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            }
        }
        try {
            const response = await Axios.get(url, data);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }
    }

    static getUserRole() {
        return UserSession.getRole();
    }

    static refreshTokens() {
        return UserSession.timeOutSession();
    }

    /**
     * Signs out the current logged user
     */
    static signout() {
        UserSession.signOut();
    }
}

export default UserService;
