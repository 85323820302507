import React from 'react';
import {CSSTransition} from 'react-transition-group';
import styles from "Content/Modal/MyModal.module.css";
import "Content/Modal/MyModal.css";
import ReactDOM from 'react-dom';

const MyModal = (props) => {
    let wrapper = React.createRef();

  // return (
    return ReactDOM.createPortal(
      <CSSTransition in={props.show} unmountOnExit timeout={{enter: 0, exit: 300}}>
          <div className={"mymodal"} ref={wrapper}  onClick={props.onClose}>
            <div className={"mymodal-content "+ props.className} onClick={e => e.stopPropagation()}>
              <div className={"mymodal-header"}>
                <h4 className={"mymodal-title"}>{props.title}</h4>
              </div>
              <div className={"mymodal-body"}>{props.children}</div>
              <div className={`mymodal-footer ${props.footerClassName}`}>
                {props.footer}
              </div>
            </div>
          </div>
          {/* <div className={styles.mymodal + " " + props.className} onClick={props.onClose}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
              <div className={styles.modalHeader}>
                <h4 className={styles.modalTitle}>{props.title}</h4>
              </div>
              <div className={styles.modalBody}>{props.children}</div>
              <div className={styles.modalFooter}>
                {props.footer}
              </div>
            </div>
          </div> */}
        </CSSTransition>,
      document.getElementById("root")
      );
};

export default MyModal;