/*
 * File: ForgotPassword.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React, { useState } from "react";
import styles from "Content/ForgotPassword/ForgotPassword.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import { FiArrowLeftCircle as ReturnIcon} from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Input from "Theme/Component/Input";
import InputSubmit from "Theme/Component/InputSubmit";
import { Alert } from "react-bootstrap";
import UserService from 'Services/Users/UserService';

/**
 * The Forgot password component
 * @param {Object} props The propos passed to the hook
 */
function ForgotPassword(props) {
    const [email, setMail] = useState("");
    const [sent, setSent] = useState(false);
    const history = useHistory();

    /**
     * Handle submission of the form
     * @param {*} event The event object triggered when form submitted
     */
     const HandleSubmit = (event) => {
        event.preventDefault();
        UserService.forgottenPassword(email);
        setSent(true);
    }

    return(
        <div className={"fullscreen center-content"}>
            <div className="block h-center-content">
                <p className="h-center-div margin-bottom-huge fourth-color large-font-2 font-weight-700">
                    {translator.decode("forgot_password.forgot_password")}
                </p>
                <form onSubmit={HandleSubmit} className="block">
                    <Input type="text"
                    value={email}
                    placeholder={translator.decode("forgot_password.placeholder")}
                    className="h-center-div extra-lineheight-2 block"
                    onChange={e => setMail(e.target.value)}
                    id={styles.input}/>
                    {sent &&
                        <Alert variant="success" className="block" id={styles.alert}>
                            {translator.decode("forgot_password.mail_sent")}
                        </Alert>
                    }
                    <InputSubmit
                    value={translator.decode("forgot_password.send")}
                    placeholder={translator.decode("forgot_password.placeholder")}
                    className="h-center-div extra-lineheight-2 block"
                    id={styles.input_submit}/>
                </form>
            </div>
            <div id={styles.return} onClick={history.goBack}>
                    <ReturnIcon id={styles.returnIcon}/>
            </div>
        </div>
    );
};

export default ForgotPassword;