import {useState} from "react";
import Form from 'react-bootstrap/Form';
import MyModal from './MyModal';
import translator from "MultiLanguage/TranslationWrapper";
import InputSubmit from "Theme/Component/InputSubmit";
import Input from "Theme/Component/Input";
import styles from "Content/Modal/CounterPropositionModal.module.css";
import OfferUtils from 'Utils/OfferUtils';

function CounterProposition(props) {
    const [ newPrice, setNewPrice ] = useState(props.offer.price);

    const handleSubmit = async () => {
        await OfferUtils.handleSubmit(props.offer, Number(newPrice), true);
        props.onClose();
    }

    const validationFooter = () => {
        return (<>
        <InputSubmit onClick={() => handleSubmit()}
            className={`block h-center-div full-width font-weight-700 large-font-2 margin-bottom ${styles.input_submit} ${styles.closeButton}`}
            value={translator.decode("offers.counterOffer.send")}/>
        </>)
    }

    const counterPropositionBody= () => {
        return (
            <div className={`container-flex no-scroll ${styles.moved}`}>
            <Form onSubmit={(e) => handleSubmit()} id={ styles.div_form } className={styles.centered}>
                <div className={`row no-scroll ${styles.noScroll}`}>
                    <div  className={`col-12 ${styles.centered}`}>
                        <Form.Group className={`${styles.inputGroup}`} controlId="price">
                        <div  className={`col-12 ${styles.centered}`}>
                            <Form.Label className={`${styles.colorEmphasis} ${styles.centered}`}>{translator.decode("offers.newOffer.placeholder.price")}</Form.Label>
                        </div>
                        <div  className={`col-12 ${styles.centered}`}>
                            <Input
                                    className={`h-center-div ${styles.input} ${styles.centered}`}
                                    type="number"
                                    min={1}
                                    value={newPrice}
                                    placeholder={newPrice.toString()}
                                    onChange={e => setNewPrice(e.target.value)}
                                    required={true}
                                    />
                        </div>
                        </Form.Group>
                    </div>
                </div>
            </Form>
        </div>
        );
    }

    return (
            <MyModal id="CounterProposalModal" onClose={props.onClose} className={styles.CounterProposalModal} title={`${translator.decode("offers.counterOffer.title")} ${props.offer.regNb} (n°${props.offer.id})`} show={props.show} onClose={props.onClose} footer={validationFooter()}>
                {counterPropositionBody()}
            </MyModal>
        );
}

export default CounterProposition;