/*
 * File: LoadingScreen.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React from "react";
import styles from "Content/LoadingScreen/LoadingScreen.module.css";
import Spinner from "react-bootstrap/Spinner";

/**
 * Component Loading Screen
 * @param {Object} props The props passed to the component
 */
function LoadingScreen(props) {
    return (
        <div id={styles.divBackground} className={"v-center-content h-center-content"}>
            <Spinner animation="border" id={styles.spinner}/>
        </div>
    );
};

export default LoadingScreen;