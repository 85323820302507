/*
 * File: ServiceGeneral.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import UserSession from '../Utils/UserSession';
import RouterPathResolver from "Config/Router/RouterPathResolver";
import AuthenticationError from "Error/AuthenticationError";

/**
 * Helps manage and centralize common behavior of services
*/
class ServiceGeneral {
    static END = "end";
    static CONTINUE = "continue";

    /**
     * Manage the response of axios (Redirect to login if 401, redirect to page error if 500)
     * @param {Object} data The data returned by axios
     * @param {*} history The history object of react router
     * @returns continue if we should continue operations, end if the compoenent calling this function should stop
    */
   static handleAxiosResponse(data) {
        // function GetLocation() {
        //     let loc = useLocation();
        //     return loc;
        // }
        const status = data.status;

        switch (status){
            case 500:
                return ServiceGeneral.END;
            case 401:
                throw new AuthenticationError("Identification expired.");
            default :
                break;
        }
        return ServiceGeneral.CONTINUE;
    }

    /**
     * Check if the server is reached, if yes returns the response otherwise returns a server error like response
     * @param {*} error The axios error
     */
    static interpretError(error) {
        console.error(`${JSON.stringify(error)}`)
        if (error.response === undefined && error.data === undefined) {
            return {
                status : error.status ?? 500,
                data : {}
            };
        } else if (error.response.status === 401 && error.status === 401) {
            throw new AuthenticationError("Identification expired.");
        }

        return error.response ?? error.data;
    }
}

export default ServiceGeneral;
