/*
 * File: Legal.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React from "react";
import styles from "Content/Legal/Legal.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import { FiArrowLeftCircle as ReturnIcon} from "react-icons/fi";
import { useHistory } from "react-router-dom";

/**
 * The Legal component
 * @param {Object} props The propos passed to the hook
 */
function Legal(props) {
    const history = useHistory();
    
    return(
        <div className={"full-width " + styles.container}>
            <div className="paragraph full-width block">
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.title")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.text")}</p>
                <p className="medium-lineheight-2 small-font-2 margin-bottom-huge">{translator.decode("legal.text2")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article1")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_1")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_2")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_3_1")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_3_2")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_3_3")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_3_4")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_3_5")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_3_6")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph1_3_7")}</p>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph1_4")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article2")}</h4>                
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph2")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article3")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph3")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article4")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph4")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph4_1")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph4_2")}</p>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph4_3")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article5")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph5")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph5_1")}</p>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph5_2")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article6")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph6")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article7")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph7")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article8")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_1")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_2")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_3")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_4")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_5")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_6")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_7")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph8_8")}</p>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph8_9")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article9")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9_1")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9_2")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9_3")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9_4")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9_5")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9_6")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph9_7")}</p>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph9_8")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article10")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph10")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article11")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph11")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph11_2")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph11_3")}</p>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph11_4")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article12")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph12")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article13")}</h4>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_1")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_2")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_3")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_4")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_5")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_6")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_7")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_8")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_9")}</p>
                <p className="medium-lineheight-2 no-margin small-font-2">{translator.decode("legal.paragraph13_10")}</p>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph13_11")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article14")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph14")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article15")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph15")}</p>
                <h4 className="medium-font-1 font-weight-900 margin-bottom-huge">{translator.decode("legal.article16")}</h4>
                <p className="medium-lineheight-2 margin-bottom-huge small-font-2">{translator.decode("legal.paragraph16")}</p>
            </div>
            <div id={styles.return} onClick={history.goBack}>
                    <ReturnIcon id={styles.returnIcon}/>
            </div>
        </div>
    );
};

export default Legal;