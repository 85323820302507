/*
 * File: PreviousPrice.js
 * Project: front
 * File Created: Wednesday, 10th April 2024 10:12:45
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 14th May 2024 12:21:32
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import {useEffect, useState} from 'react';
import { Tooltip } from 'react-bootstrap';
import OfferService from "../../Services/Offers/OfferService"
import translator from '../../MultiLanguage/TranslationWrapper';

function PreviousPrice(props) {
    let [offers, setOffers] = useState([])

    useEffect(() => {
        if (props.previousOffers) {
            let val = props.previousOffers;
            let tmpOffers = []
            if (val.current) {
                let priceArray = val.current.sort((a, b) => new Date(b) - new Date(a)).map((offer) => {
                    return <p><span>{new Date(offer.at).toLocaleString()} - </span><span>{offer.counterOffer ? translator.decode("dynamic.counterOffer") : translator.decode("dynamic.offer")}:</span>{offer.price}</p>
                });
                tmpOffers = priceArray;
            }
            if (val.previous) {
                let previousOffers = {}
                val.previous.forEach((oldOffer) => {
                    let companyName = oldOffer["author"]?.company;
                    if (companyName in previousOffers)
                        previousOffers[companyName].push(oldOffer)
                    else
                        previousOffers[companyName] = [oldOffer]
                })
                let previousPriceArray = Object.entries(previousOffers).map(([companyName, offers]) => {
                    return <>
                        <p><strong><u>{companyName}:</u></strong></p>
                        {
                            offers.sort((a, b) => new Date(b.at) - new Date(a.at)).map((offer) => {
                                return <>
                                    <p><span>{new Date(offer.at).toLocaleString()} - </span><span>{offer.counterOffer ? translator.decode("dynamic.counterOffer") : translator.decode("dynamic.offer")}:</span>{offer.price}</p>
                                </>
                            })
                        }
                    </>
                });
                tmpOffers = [...tmpOffers, ...previousPriceArray];
            }
            setOffers(tmpOffers);
        }
    }, [])

    return <div style={{overflowY: "auto", zIndex: 151235, maxHeight: "80%"}}>
        {
            offers
        }
    </div>
}

export default PreviousPrice;
