/*
 * File: CookieWrapper.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import CookieInstance from "Cookies/Cookie";

/**
 * Factory to create cookie instance Object
 * @param {Object} target The instance of cookie
 */
const CookieFactory = (target) => ({
  setCookie : (field, data) => target.setCookie(field, data),
  getCookie : (field) => target.getCookie(field),
  removeCookie : (field) => target.removeCookie(field)
});

const cookie = CookieFactory(new CookieInstance());

export default cookie;