/*
 * File: CookiePathResolver.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */
const COOKIE_CONFIG = {
    USER : {
        FIELD_USER : "user/user_id",
        FIELD_USER_ID : "user/user_id/id",
        ROLE: "user/role",
        TOKEN : "user/token",
        REFRESH_TOKEN : "user/refresh",
    }
};

export default COOKIE_CONFIG;