import Axios from "axios";
import Config from "Config/Config";
import ServiceGeneral from "Services/ServiceGeneral";
import UserSession from "Utils/UserSession";
import AuthenticationError from "Error/AuthenticationError";
import Compressor from "compressorjs";

class PictureService {
    static compressImage(image, callback) {
        return new Compressor(image, {
            mimeType: "image/jpeg",
            quality: 0.6,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            async success(result) {
                await callback(result);
                return 1;
            },
            error(err) {
              console.log(err.message);
            },
          });
    }

    static transformPicToBase64(file) {
        let reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            reader.onload = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }

    static async pushToServ(immat, name, offerId, config, pictureArray, file) {
        const picture = {immat, pictureb64: await PictureService.transformPicToBase64(file), name, offerId};
        try {
            const response = Axios.post(Config.BACKEND.BASEURL + Config.BACKEND.PATHS.ADD_PICTURE, picture, config);
            if (await ServiceGeneral.handleAxiosResponse(response) === "end") {
                return undefined;
            }
            pictureArray.push(response);
            return response;
        } catch(e) {
            if (e instanceof AuthenticationError)
                throw e;
            const data = ServiceGeneral.interpretError(e);
            return data;
        }
    }

    static async uploadPicture(file, immat, name, offerId, pictureArray) {
        var tokens = UserSession.getAccessToken();

        var config = {
            headers : {}
        }

        if (tokens !== undefined) {
            config.headers = {
                Authorization : tokens.token_type + " " + tokens.access_token
            };
        }
        return PictureService.compressImage(file, PictureService.pushToServ.bind(null, immat, name, offerId, config, pictureArray));
    }
}

export default PictureService;