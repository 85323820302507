/*
 * File: NewPassword.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React, { useState } from "react";
import styles from "Content/NewPassword/NewPassword.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import { FiArrowLeftCircle as ReturnIcon} from "react-icons/fi";
import { useHistory, useParams, Redirect } from "react-router-dom";
import Input from "Theme/Component/Input";
import InputSubmit from "Theme/Component/InputSubmit";
import { Alert } from "react-bootstrap";
import UserService from 'Services/Users/UserService';
import Spinner from "react-bootstrap/Spinner";

/**
 * The New password component
 * @param {Object} props The propos passed to the hook
 */
function NewPassword(props) {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();
    const {encrypted} = useParams();
    const [waitSpinner, setWaitSpinner] = useState(false);

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Handle submission of the form
     * @param {*} event The event object triggered when form submitted
     */
     const HandleSubmit = async (event) => {
        event.preventDefault();
        setWaitSpinner(true);
        if (password !== password2) {
            setError(true);
            setWaitSpinner(false);
            return;
        }
        await UserService.setNewPassword(password, encrypted);
        setWaitSpinner(false);
        setSent(true);
        await timeout(5000);
        Redirect("/");
    }

    return(
        <div className={"fullscreen center-content"}>
            <div className="block h-center-content">
                <p className="h-center-div margin-bottom-huge fourth-color large-font-2 font-weight-700">
                    {translator.decode("new_password.new_password")}
                </p>
                <label htmlFor={styles.input1} className={styles.label}>{translator.decode("new_password.label1")}</label>
                <form onSubmit={HandleSubmit} className="block">
                    <Input type="password"
                    value={password}
                    placeholder={translator.decode("new_password.placeholder")}
                    className="h-center-div extra-lineheight-2 block"
                    onChange={e => setPassword(e.target.value)}
                    id={styles.input1}/>
                    <label htmlFor={styles.input2} className={styles.label}>{translator.decode("new_password.label2")}</label>
                    <Input type="password"
                    value={password2}
                    placeholder={translator.decode("new_password.placeholder")}
                    className="h-center-div extra-lineheight-2 block"
                    onChange={e => setPassword2(e.target.value)}
                    id={styles.input2}/>
                    {sent &&
                        <Alert variant="success" className="block" id={styles.alert}>
                            {translator.decode("new_password.mail_sent")}
                        </Alert>
                    }
                    <InputSubmit
                    value={translator.decode("new_password.send")}
                    placeholder={translator.decode("new_password.placeholder")}
                    className="h-center-div extra-lineheight-2 block"
                    id={styles.input_submit}/>
                </form>
                {
                    waitSpinner &&
                    <div id={styles.divBackground} className={`v-center-content h-center-content`}>
                        <Spinner animation="border" id={styles.spinner}/>
                    </div>
                }
            </div>
            <div id={styles.return} onClick={history.goBack}>
                    <ReturnIcon id={styles.returnIcon}/>
            </div>
        </div>
    );
};

export default NewPassword;