/*
 * File: Users.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React, { useEffect, useState, useRef } from "react";
import {HEADERS} from "Hooks/HookUsers";
import ResetUserPassword from "Content/Modal/ResetUserPassword";
import translator from "MultiLanguage/TranslationWrapper";
import styles from "Content/Users/Users.module.css";

/**
 * The UserList component
 * @param {Object} props The propos passed to the hook
 */
function UsersList(props) {
    const [showReset, setShowReset] = useState(false);
    const userEmail = useRef("");

    useEffect(() => {
        console.log(props.users);
    }, [props.users])

    const handleRenewUserPwd = (email, event) => {
        userEmail.current = email;
        setShowReset(true);
    }

    const getContentTable = () => {
        var rows = [];
        let elements = [];
        var index = 0;
        for (var row in props.users) {
            elements = Object.keys(HEADERS).filter((elem) => {return elem != "id" && elem != "commercial";}).map((elem, idx) =>
            {
                if (elem != "email" && elem != "role" && elem != "company" && elem != "address") {
                    return (
                        <td key={"line_" + elem + "_" + idx} className={index == props.selectedElementIdx ? styles.selected: ""}>
                                <input type="text" elem={elem} placeholder={props.users[row][elem]} className="no-margin" style={{"width": `${props.users[row][elem] ? props.users[row][elem].toString().length + 1: 10}ch`}} onBlur={props.handleUpdateUser.bind(null, row)} />
                        </td>)

                } else if (elem == "company" || elem == "address") {
                    return (
                        <td key={"line_" + elem + "_" + idx} className={index == props.selectedElementIdx ? styles.selected: ""}>
                                <input type="text" title={props.users[row][elem]} elem={elem} placeholder={props.users[row][elem]} className="no-margin" onBlur={props.handleUpdateUser.bind(null, row)} />
                        </td>)
                } else
                    return (
                        <td key={"line_" + elem + "_" + idx} className={index == props.selectedElementIdx ? styles.selected: ""}>
                                <p className="no-margin">{props.users[row][elem]}</p>
                        </td>)
        })
            if (props.adminsOptions.length > 0 && props.adminsOptions[0] != undefined && props.users[row]["role"] == "user") {
                if (props.users[row]["commercialID"] == -1 || props.users[row]["commercialID"] == null) {
                    elements.push(
                        <td key={"line_commercial_" + row} className={index == props.selectedElementIdx ? styles.selected: ""}>
                            <select name="commercial" onChange={props.handleUpdateUser.bind(null, row)}>
                                {[<option value={-1} key="defaultKey">{translator.decode("dynamic.choose_admin")}</option>,...props.adminsOptions.map((elem) =>
                                        <option value={elem.value} key={`admin${elem.value}`}>{elem.label}</option>)]}
                            </select>
                        </td>
                    )
                } else {
                    elements.push(
                        <td key={"line_commercial_" + row} className={index == props.selectedElementIdx ? styles.selected: ""}>
                            <select name="commercial" onChange={props.handleUpdateUser.bind(null, row)} value={props.users[row]["commercialID"]}>
                                {
                                    [...props.adminsOptions.map((elem) =>
                                        <option value={elem.value} key={`admin${elem.value}`}>{elem.label}</option>)]
                                }
                            </select>
                        </td>
                    )
                }
            } else
                elements.push(
                    <td key={"line_empty_" + row} className={index == props.selectedElementIdx ? styles.selected: ""}>
                        <p className="no-margin">{""}</p>
                    </td>)

            elements.push(
                <td key={"resetUser" + row} className={index == props.selectedElementIdx ? styles.selected: ""}>
                    <button className={styles.button} title={translator.decode("renew_pwd")} onClick={handleRenewUserPwd.bind(null, props.users[row]["email"])}>{translator.decode("users.renew_pwd")}</button>
                </td>
            )
            rows.push(
                <tr id={`users${row}`} key={`$row_${row}`} onClick={(e) => props.handleSelection(e)}>
                    {elements}
                </tr>
            );
            index++;
        }
        return rows;
    };

    return(
        <React.Fragment>
            {getContentTable()}
            <ResetUserPassword show={showReset} close={(e) => {setShowReset(!showReset)}} title={translator.decode("users.renew_title").toString().replace(/\<UserEmail\>/, userEmail.current.toLocaleLowerCase())} email={userEmail.current} />
        </React.Fragment>
    );
};

export default UsersList;