/*
 * File: Link.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */
import { Link } from "react-router-dom";
import styled from "styled-components";

/**
* Styled component Link
* Type: input (provided via props)
*/
const LinkElem = styled(Link)`
color: ${props => props.theme.colors.third};
text-decoration: underline;
&:hover, &:focus {
    color: ${props => props.theme.colors.third};
    cursor: pointer;
}
`;

export default LinkElem;
