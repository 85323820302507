
import React from "react";

import styles from "Content/Formulaire/Picture/Picture.module.css";
import ImageUpload from "Content/Formulaire/ImageUpload/ImageUpload";
import Form from 'react-bootstrap/Form';

function Picture(props) {
    return (
        <React.Fragment>
            <Form.Group className={`${styles.inputGroup} ${props.className}`} controlId={props.controlId}>
                <div className={styles.inputPicture}>
                    <Form.Label className={`${styles.colorEmphasis}`}>{props.placeholder}</Form.Label>
                    <ImageUpload className={`${styles.input} `} inputId={props.inputId} required={props.required} onChange={props.onChange} />
                </div>
            </Form.Group>
        </React.Fragment>
    )
}

export default Picture;