import {useState} from "react";
import Form from 'react-bootstrap/Form';
import MyModal from './MyModal';
import translator from "MultiLanguage/TranslationWrapper";
import InputSubmit from "Theme/Component/InputSubmit";
import Input from "Theme/Component/Input";
import styles from "Content/Modal/PropositionModal.module.css";
import OfferService from 'Services/Offers/OfferService';
import OfferUtils from 'Utils/OfferUtils'

function Proposition(props) {
    const [ newPrice, setPrice ] = useState(0);
    const [ error, setError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");

    const handleSubmit= async () => {
        var ret = await OfferUtils.handleSubmit(props.offer, Number(newPrice), false, props.isAnOldCar)
        console.log(`Received from submit : ${JSON.stringify(ret)}`)
        if (ret.id !== undefined) {
            OfferService.propagateOfferProposition(ret.id);
            if (props.cb)
                props.cb(ret);
            props.onClose();
            setPrice(0);
        } else {
            setError(true);
            setErrorMessage(ret.data.message);
        }
    }

    const validationFooter = () => {
        return (<InputSubmit onClick={handleSubmit}
            className={"block h-center-div full-width font-weight-700 large-font-2 margin-bottom " + styles.input_submit}
            value={translator.decode("offers.newOffer.send")}/>)
    }

    const propositionBody= () => {
        return (
            <div className={`container-flex no-scroll ${styles.moved}`}>
            <Form onSubmit={handleSubmit} id={ styles.div_form } className={styles.centered}>
                <div className={`row no-scroll ${styles.noScroll}`}>
                    <div  className="col-12">
                        <Form.Group className={styles.inputGroup} controlId="price">
                        <Form.Label className={`${styles.colorEmphasis}`}>{translator.decode("offers.newOffer.placeholder.price")}</Form.Label>
                        <div  className="col-12">
                            <Input
                                    className={"h-center-div " + styles.input}
                                    type="number"
                                    min={1}
                                    value={newPrice}
                                    placeholder={translator.decode("users.addUser.placeholder.price")}
                                    onChange={e => setPrice(e.target.value)}
                                    required={true}
                                    />
                        </div>
                        </Form.Group>
                    </div>
                </div>
            </Form>
        </div>
        );
    }

    return (
        <MyModal className={styles.PropositionModal} title={translator.decode("offers.newOffer.title") + " n°" + props.offer.id + " immatriculé " + props.offer.regNb} show={props.show} onClose={() => {props.onClose(); setPrice(0); setError(false); setErrorMessage("")}} footer={!error ? validationFooter() : <></>}>
                {!error ?
                    propositionBody() :
                    <span>{errorMessage}</span>}
        </MyModal>
    );
}

export default Proposition;