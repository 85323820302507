import OfferService from 'Services/Offers/OfferService';

class OfferUtils {
    static handleSubmit= async (offer, newPrice, isACounterOffer, isAnOldOffer) => {
        const updateOffer = {
            "price": newPrice,
            "status": "encours",
            "counterOffer": isACounterOffer
        }
        var resp = await OfferService.submitOffer(offer.id, updateOffer, isAnOldOffer);
        if (isACounterOffer && resp.id)
            await OfferService.sendCounterOfferMail(resp.id);
        return resp;
    }

    static handleAccept= async (offer, isAnOldOffer) => {
        const offerBody = {
            "status": "accepted",
        }
        var resp = await OfferService.updateOffer(offer.id, offerBody, isAnOldOffer);
        await OfferService.propagateOfferStatus(resp.id);
    }

    static handleRefuse= async (offer, isAnOldOffer) => {
        const offerBody = {
            "status": "discarded",
        }
        var resp = await OfferService.updateOffer(offer.id, offerBody, isAnOldOffer);
        await OfferService.propagateOfferStatus(resp.id, isAnOldOffer);
    }

    static isOfferTooOld = (offerDate) => {
        if (offerDate == undefined)
            throw new ReferenceError("Invalid offer Date")
        var date = new Date(offerDate);
        var now = new Date();
        console.log(`Actual date: ${offerDate?.toString()}`)
        console.log(`Actual now date: ${now.toISOString()}`)
        var daysBetween = Math.round((now - date)/86400000);
        console.log(`number of days between: ${daysBetween}`);
        return daysBetween > 30;
    }

    static filterByStatus = (statusList, offers) => {
        return offers.filter(v => statusList.includes(v.status));
    }

}


export default OfferUtils;