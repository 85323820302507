/*
 * File: TranslationWrapper.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import Translator from "MultiLanguage/Translation";

/**
 * Factory to create translation instance Object
 * @param {Object} target The instance of translation
 */
const TranslatorFactory = (target) => ({
  setLanguage : (language) => target.setLanguage(language),
  getLanguage : () => target.getLanguage(),
  getAvailableLanguages : () => target.getAvailableLanguages(),
  decode : (treePosition) => target.decode(treePosition)
});

const translator = TranslatorFactory(new Translator());

export default translator;