/*
 * File: OldPrice.js
 * Project: front
 * File Created: Wednesday, 27th March 2024 09:33:17
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 5th April 2024 10:41:07
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import {useState} from 'react';
import { AiOutlineEuro as Euro, AiOutlineLike as Like, AiOutlineDislike as Dislike } from "react-icons/ai";
import styles from "Content/Car/Car.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import InputSubmit from "Theme/Component/InputSubmit";
import OfferUtils from 'Utils/OfferUtils';
import PropositionModal from 'Content/Modal/PropositionModal';
import CounterPropositionModal from 'Content/Modal/CounterPropositionModal';

function OldPrice(props) {
    const [offer, setOffer] = useState(props.offer);
    const role = props.role;
    const rowIdx = props.rowIdx;
    const lastOffer = props.offer.offers && props.offer.offers.length > 0 ? props.offer.offers[0] : undefined
    const isStillAvailable = Math.floor((Date.now() - new Date(props.offer.createDate).getTime()) / (1000 * 60 * 60 * 24)) < 30 && props.offer.status == "encours";
    const selectedElementIdx = props.selectedElementIdx;
    const [showProposition, setShowProposition] = useState(false);
    const [showCounterProposition, setShowCounterProposition] = useState(false);

    return (
            <td key={`line_price_${rowIdx}`} className={`${styles.centered} ${styles.priceCell} ${(rowIdx === selectedElementIdx ? "selected": "")}`}>
                <div className={`container ${styles.row}`}>
                    <div className="row">
                        <div  className={`col-12`}>
                            { offer.price !== null && offer.price !== undefined ?
                                <p className="no-margin">{offer.price} €</p> :
                                <p className="no-margin">{translator.decode("offers.nothing")}</p>
                            }
                        </div>
                    </div>
                    <div className="row" onClick={e => e.stopPropagation()}>
                        <div  className={`col-12 ${styles.inputButtons}`}>
                    { isStillAvailable && role === "admin" && !offer.counterOffer ?
                        <span className={styles.iconInputBtn} onClick={(e) => {setShowProposition(true);}} >
                            <Euro className={styles.buyIcons}/>
                            <InputSubmit id={`submit-${rowIdx}`}
                                className={styles.input_submit}
                                value={translator.decode("offers.counterOffer.send")} variant="warning"/>
                        </span> : null
                    }
                    { isStillAvailable && role === "admin" && ((offer.price == null || offer.price == undefined) || !offer.counterOffer || OfferUtils.isOfferTooOld(offer.createDate)) ?
                        <span className={styles.iconInputBtn} onClick={(e) => props.handleRefuse(offer)}>
                            <Dislike className={styles.buyIcons}/>
                            <InputSubmit id={`refuse-${rowIdx}`}
                                className={styles.input_submit}
                                value={translator.decode("offers.counterOffer.refuse")} variant="negative"/>
                        </span> : null
                    }
                    { offer.price !== null && offer.price !== undefined && offer.status == "encours" ?
                        <>
                            { (role === "user" && !offer.counterOffer) && isStillAvailable ?
                                <span className={styles.iconInputBtn} onClick={(e) => {setShowCounterProposition(true)}} >
                                    <Euro className={styles.buyIcons}/>
                                    <InputSubmit id={`submit-${rowIdx}`} onClick={(e) => setShowCounterProposition(true)}
                                        className={styles.input_submit}
                                        value={translator.decode("offers.counterOffer.send")} variant="warning"/>
                                </span> : null
                            }
                            {((role === "user" && !offer.counterOffer) || (role === "admin" && offer.counterOffer)) ?
                                <>
                                { isStillAvailable ?
                                    <span className={styles.iconInputBtn} onClick={(e) => props.handleAccept(offer)}>
                                        <Like className={styles.buyIcons}/>
                                        <InputSubmit id={`accept-${rowIdx}`}
                                            className={styles.input_submit}
                                            value={translator.decode("offers.counterOffer.accept")} variant="positive"/>
                                    </span> : null
                                }
                                { offer.status == "encours" ?
                                    <span className={styles.iconInputBtn} onClick={(e) => props.handleRefuse(offer)}>
                                        <Dislike className={styles.buyIcons}/>
                                        <InputSubmit id={`refuse-${rowIdx}`}
                                            className={styles.input_submit}
                                            value={translator.decode("offers.counterOffer.refuse")} variant="negative"/>
                                    </span> : null
                                }
                                </> : null
                            }
                        </> : null
                        }
                        </div>
                    </div>
                </div>
                {role === "user" && offer && offer.price !== null ?
                    <CounterPropositionModal show={showCounterProposition} onClose={() => {setShowCounterProposition(false); props.onCounterClose();; props.handleUpdatePrice();} } offer={{id: props.offer.id, regNb: props.offer.regNb, price: lastOffer ? lastOffer.price : 0}}/> : null
                    // <CounterPropositionModal show={showCounterProposition} onClose={() => {setShowCounterProposition(false); props.onCounterClose();; props.handleUpdatePrice();} } offer={offer}/>
                }
                {role === "admin" &&
                    <PropositionModal show={showProposition} onClose={() => {setShowProposition(false);props.onPropositionClose(); props.handleUpdatePrice();}} offer={offer} isAnOldCar={true} />
                }
            </td>);
}

export default OldPrice;
