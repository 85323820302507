import React from 'react';
import {CSSTransition} from 'react-transition-group';
import ReactDOM from 'react-dom';
import translator from "MultiLanguage/TranslationWrapper";
import Button from "Theme/Component/Button";
import MyModal from './MyModal'

import styles from "Content/Modal/HideOffers.module.css";

const HideOffers = props => {
  // return (
    console.error("~~~~~~~~~ TEST ~~~~~~~~~~~ : " + new Date().toLocaleTimeString())

    function validationFooter() {
      return (
        <>
          <Button onClick={props.onReset} variant="negative">
            {translator.decode("dynamic.no")?.toUpperCase()}
          </Button>
          <Button onClick={props.onValidation} variant="positive" className={`${styles.secondButton}`}>
            {translator.decode("dynamic.yes")?.toUpperCase()}
          </Button>
        </>
      )
    }

    return (
      <MyModal show={props.show}
        className={styles.ValidOfferModal}
        title={props.title}
        onClose={props.onReset}
        footer={validationFooter()}
        >
          {props.children}
      </MyModal>
    )
};

export default HideOffers;
