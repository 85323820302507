/*
 * File: Input.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */
import styled from "styled-components";
import Select from "react-select";

/**
* Styled component Input
* Type: input (provided via props)
*/

const mySelect = styled(Select).attrs(props => (
    {
        options: props.options,
        className: "medium_font_2",
        placeholder: props.placeholder,
        disabled: props.options !== undefined && props.options?.length > 1 ? false : true
    }
))`
padding-right: 10px;
padding-left: 10px;
color: ${props => props.theme.colors.third};
background-color: ${props => props.theme.colors.first};
border-top: 0px;
border-left: 0px;
border-right: 0px;
border-bottom: 1px solid ${props => props.theme.colors.fourth};
&:hover, &:focus {
    outline: none;
    background-color: ${props => props.theme.colors.fifth};
}
`;

export default mySelect;
