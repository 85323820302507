import React, {useState} from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import styles from "Theme/Component/LangSelector/LangSelector.module.css";

import translator from "MultiLanguage/TranslationWrapper";
import { useLanguageContext } from "Utils/Context";
import UserSession from "Utils/UserSession";

function LangSelector(props) {
    const json = require("../../../Config/Translation/availableLang.json");
    const flags = [];
    const language = useLanguageContext();
    let curLang = language["language"];
    if (curLang === undefined && UserSession.getUser().preferedLanguage !== undefined)
        curLang = UserSession.getUser().preferedLanguage;
    const defaultLang = curLang !== undefined ? {[curLang]: <img key={`key-${curLang}`}  className={styles.image} src={json[curLang]["image"]} alt=""/>} : {"fr": <img key='fr-lang'  className={styles.image} src={json["fr"]["image"]} alt=""/>}
    const [ currentLang, setCurrentLang ] = useState(defaultLang);
    for (var key in json) {
        flags.push({[key]: <img key={`flag-${key}`} className={styles.image} src={json[key]["image"]} alt=""/>});
    }

    function setNewCurrentLang(newElem) {
        const elemKey = Object.keys(newElem)[0];
        const foundElem = flags.find((elem) => { return Object.keys(elem)[0] === elemKey; } );
        setCurrentLang(foundElem);
        translator.setLanguage(elemKey);
        language["language"] = elemKey;
        language["setLanguage"](elemKey);
    }

    return (<React.Fragment>
        <div id={styles.dropdown} className="center-content">
        <Dropdown className={"d-inline mx-2"}>
            <Dropdown.Toggle id="dropdown-autoclose-true" className={styles.toggle}>
                    {Object.values(currentLang)}
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdownMenu}>
                {
                    flags.filter((elem) => {
                        var keys = Object.keys(elem);
                        var curkeys = Object.keys(currentLang);
                        return keys[0] !== curkeys[0];
                    }).map((elem, idx) => {
                        var keys = Object.keys(elem);
                        return(<Dropdown.Item className={styles.dropdownItem} key={`dropdownElem-${keys[0]}-${idx}`} onClick={() => {setNewCurrentLang(elem)}}>{Object.values(elem)[0]}</Dropdown.Item>);
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
        </div>
    </React.Fragment>);
}

export default LangSelector;