/*
 * File: Config.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

/**
 * The enum of the modes
 */
const ENUM_MODE = {
    DEV : "dev",
    PROD : "prod",
    STAGING: "staging"
}

/**
 * Config shared between the different modes
 */
const Common = {
    LANGUAGES : [
        "fr",
        "en",
        "es"
    ]
};

/**
 * The internal config for the dev mode
 */
const Dev = {
    COMMON: Common,
    BACKEND : {
        BASEURL : "http://localhost:4000/api",
        PATHS : {
            LOGIN: "/auth",
            GET_USER: "/users",
            ADD_USER: "/users/create",
            GET_INTEL_FROM_REG: "/autobiz/immatriculation",
            GET_BRANDS: "/autobiz/brands",
            GET_MODELS: "/autobiz/%s/models",
            GET_INTELS: "/autobiz/%s/%s/%s",
            GET_OFFERS: "/offers",
            UPDATE_OFFERS: "/offers",
            ADD_OFFER: "/offers",
            GET_CARS: "/cars",
            UPDATE_CARS: "/cars",
            ADD_OFFER_ON_CAR: "/cars",
            VALIDATE_OFFER: "/validate",
            PROPAGATE_OFFER_TO_USER: "/propagate",
            PROPAGATE_STATUS: "/status_change",
            COUNTER_OFFER: "/counter_offer",
            ADD_PICTURE: "/pictures",
            EXTRACT_OFFERS: "/excel/extract_offers",
        }
    },
    COOKIE : {
        COOKIE_TIME_DAYS : "10"
    }
};

/**
 * The internal config for production mode
 */
const Prod = {
    COMMON: Common,
    BACKEND : {
        BASEURL : "https://api.autobuypro.fr/api",
        PATHS : {
            LOGIN: "/auth",
            GET_USER: "/users",
            ADD_USER: "/users/create",
            GET_INTEL_FROM_REG: "/autobiz/immatriculation",
            GET_BRANDS: "/autobiz/brands",
            GET_MODELS: "/autobiz/%s/models",
            GET_INTELS: "/autobiz/%s/%s/%s",
            GET_OFFERS: "/offers",
            UPDATE_OFFERS: "/offers",
            ADD_OFFER: "/offers",
            GET_CARS: "/cars",
            UPDATE_CARS: "/cars",
            ADD_OFFER_ON_CAR: "/cars",
            VALIDATE_OFFER: "/validate",
            PROPAGATE_OFFER_TO_USER: "/propagate",
            PROPAGATE_STATUS: "/status_change",
            COUNTER_OFFER: "/counter_offer",
            ADD_PICTURE: "/pictures",
            EXTRACT_OFFERS: "/excel/extract_offers",
        }
    },
    COOKIE : {
        COOKIE_TIME_DAYS : "31"
    }
};

/**
 * The internal config for production mode
 */
const Staging = {
    COMMON: Common,
    BACKEND : {
        BASEURL : "https://api.testing.autobuypro.fr/api",
        PATHS : {
            LOGIN: "/auth",
            GET_USER: "/users",
            ADD_USER: "/users/create",
            GET_INTEL_FROM_REG: "/autobiz/immatriculation",
            GET_BRANDS: "/autobiz/brands",
            GET_MODELS: "/autobiz/%s/models",
            GET_INTELS: "/autobiz/%s/%s/%s",
            GET_OFFERS: "/offers",
            UPDATE_OFFERS: "/offers",
            ADD_OFFER: "/offers",
            GET_CARS: "/cars",
            UPDATE_CARS: "/cars",
            ADD_OFFER_ON_CAR: "/cars",
            VALIDATE_OFFER: "/validate",
            PROPAGATE_OFFER_TO_USER: "/propagate",
            PROPAGATE_STATUS: "/status_change",
            COUNTER_OFFER: "/counter_offer",
            ADD_PICTURE: "/pictures",
            EXTRACT_OFFERS: "/excel/extract_offers",
        }
    },
    COOKIE : {
        COOKIE_TIME_DAYS : "31"
    }
};

var Config = undefined;

if (process.env.REACT_APP_MODE === ENUM_MODE.PROD)
    Config = Prod;
else if (process.env.REACT_APP_MODE === ENUM_MODE.DEV)
    Config = Dev;
else if (process.env.REACT_APP_MODE === ENUM_MODE.STAGING)
    Config = Staging;
else
    throw Error("Invalid mode: " + process.env.REACT_APP_MODE);

Config["MODE"] = process.env.REACT_APP_MODE;
Config["ENUM_MODE"] = ENUM_MODE;

export default Config;
