/*
 * File: Router.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React, {useEffect, useState} from "react";
import {
    BrowserRouter,
    Switch,
    Route
  } from "react-router-dom";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import Login from "Content/Login/Login";
import Legal from "Content/Legal/Legal";
import Users from "Content/Users/Users";
import Offers from "Content/Offers/Offers";
import OfferForm from "Content/Formulaire/Form";
import NavBar from "Content/NavBar/NavBar";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { isLoggedInMiddleware, canDoMiddleware, OPERATIONS } from "Guards/Guards";
import ACPathResolver from "Config/AC/AccessControlPathResolver";
import ForgotPassword from "Content/ForgotPassword/ForgotPassword";
import NewPassword from "Content/NewPassword/NewPassword";
import {Context} from "Utils/Context";
import translator from "MultiLanguage/TranslationWrapper";

/**
 * Component Router
 * Used to handle every navigation events
 * Also act as the Guard Manager
 */
function RouterComponent() {
    const [ forceUpdate, setForceUpdate ] = useState(0);
    const tmp = translator.actualLanguage === null || translator.actualLanguage === undefined ? "fr" : translator.actualLanguage;
    const [ language, setLanguage ] = useState(translator.actualLanguage === null || translator.actualLanguage === undefined ? "fr" : translator.actualLanguage);

    useEffect(() => {
        setForceUpdate(forceUpdate + 1);
        setLanguage(translator.actualLanguage);
    }, [translator.actualLanguage])

    return (
        <BrowserRouter>
            <GuardProvider guards={[isLoggedInMiddleware, canDoMiddleware]}>
                <Switch>
                    <Context.Provider value={ { language, setLanguage} }>
                        <GuardedRoute exact path={RouterPathResolver.routeLogin.path} meta={{src : "login"}}>
                            <Login/>
                        </GuardedRoute>
                        <Route exact path={RouterPathResolver.routeLegal.path}>
                            <Legal/>
                        </Route>
                        <Route exact path={RouterPathResolver.routeForgotPassword.path}>
                            <ForgotPassword/>
                        </Route>
                        <Route path={RouterPathResolver.routeNewPassword.path}>
                            <NewPassword/>
                        </Route>
                        <GuardedRoute exact path={RouterPathResolver.routeSellsOffers.path} meta={{
                            role : {
                                value : true,
                                field : ACPathResolver.SELLSOFFERS,
                                operations : [OPERATIONS.read]
                            }}}>
                            <NavBar>
                                <Offers />
                            </NavBar>
                        </GuardedRoute>
                        <GuardedRoute exact path={RouterPathResolver.routeUser.path} meta={{
                            role : {
                                value : true,
                                field : ACPathResolver.USER,
                                operations : [OPERATIONS.read]
                            }}}>
                            <NavBar>
                                <Users/>
                            </NavBar>
                        </GuardedRoute>
                        <GuardedRoute exact path={RouterPathResolver.routeForm.path} meta={{
                            role : {
                                value : true,
                                field : ACPathResolver.FORM,
                                operations : [OPERATIONS.read]
                            }}}>
                            <NavBar>
                                <OfferForm />
                            </NavBar>
                        </GuardedRoute>
                    </Context.Provider>
                </Switch>
            </GuardProvider>
        </BrowserRouter>
    );
};

export default RouterComponent;
