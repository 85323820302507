/*
 * File: Users.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import {useState} from "react";

const HEADERS = {
    "company": "company",
    "lastname": "lastname",
    "firstname": "firstname",
    "address": "adress",
    "addressComp": "adressComp",
    "codePostal": "codePostal",
    "city": "city",
    "state": "state",
    "country": "country",
    "phoneNumber": "phoneNumber",
    "email": "email",
    "role": "role",
    "commercial": "commercial",
}

/**
 * The User table hook
 * @param {Object} props The propos passed to the hook
 */
function useFilters(props) {
    const [societe, setSociete] = useState("");
    const [lastname, setLastname] = useState("");
    const [firstname, setFirstname] = useState("");
    const [postal_code, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [group, setGroup] = useState("");

    var result = {};
    result[HEADERS.company] = {"value": societe, "set": setSociete};
    result[HEADERS.lastname] = {"value": lastname, "set": setLastname};
    result[HEADERS.firstname] = {"value": firstname, "set": setFirstname};
    result[HEADERS.postalCode] = {"value": postal_code, "set": setPostalCode};
    result[HEADERS.city] = {"value": city, "set": setCity};
    result[HEADERS.state] = {"value": county, "set": setCounty};
    result[HEADERS.country] = {"value": country, "set": setCountry};
    result[HEADERS.phone] = {"value": phone, "set": setPhone};
    result[HEADERS.email] = {"value": mail, "set": setMail};
    result[HEADERS.role] = {"value": group, "set": setGroup};
    return result;
};

/**
 * The sorting elements table hook
 * @param {Object} props The propos passed to the hook
 */
 function useFiltersSorting(props) {
    const [sort, setSort] = useState("");
    const [sortOrder, setSortOrder] = useState(0);

    return {
        "sort": {"value": sort, "set": (elem) => {setSort(elem); setSortOrder(0)}},
        "order": {"value": sortOrder, "set": setSortOrder},
        "headers": HEADERS
    };
};

export {useFilters, useFiltersSorting, HEADERS};