/*
 * File: NavBar.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React from "react";
import styles from "Content/NavBar/NavBar.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import { FiMenu as Menu} from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import Dropdown from 'react-bootstrap/Dropdown';
import UserSession from "Utils/UserSession";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import { canDo, OPERATIONS } from "Guards/Guards";
import AcPathResolver from "Config/AC/AccessControlPathResolver";
import LangSelector from "Theme/Component/LangSelector/LangSelector";
import { useLanguageContext } from "Utils/Context";

/**
 * The NavBar component
 * @param {Object} props The propos passed to the hook
 */
function NavBar(props) {
    const history = useHistory();
    const theme = useTheme();
    const lang = useLanguageContext();
    const signout = () => {
        UserSession.signOut();
        history.push(RouterPathResolver.routeLogin.path)
    };

    return(
        <React.Fragment>
            <div className="block full-width shadow-bottom" id={styles.navbarDiv}>
                <div id={styles.navbar} className={"flex-container flex-valign-center"}>
                    <div>
                        <img id={`${styles.logo}`} src={theme.logos.logo_horizontal}
                            alt={translator.decode("logo.description")}>
                        </img>
                    </div>
                    <div className={`flex-container flex-valign-center full-width`}>
                        <div className={`flex-grow flex-container flex-halign-center inline ${styles.navbarOptions}`}>
                            {canDo([OPERATIONS.read], AcPathResolver.FORM) &&
                                <p className={`fit-content cursor-pointer no-margin margin-left-right medium-font-2 secondary-color font-weight-700 ${styles.fontSize}`}
                                onClick={() => (history.push(RouterPathResolver.routeForm.path))}>
                                    {translator.decode("navbar.form")}
                                </p>
                            }
                            {canDo([OPERATIONS.read], AcPathResolver.SELLSOFFERS) &&
                                <p className={`fit-content cursor-pointer no-margin margin-left-right medium-font-2 secondary-color font-weight-700 ${styles.fontSize}`}
                                onClick={() => (history.push(RouterPathResolver.routeSellsOffers.path))}>
                                    {canDo([OPERATIONS.read], AcPathResolver.SELLSOFFERS) ? translator.decode("navbar.sellsoffers") : translator.decode("navbar.offers")}
                                </p>
                            }
                            {canDo([OPERATIONS.read], AcPathResolver.USER) &&
                                <p className={`fit-content cursor-pointer no-margin margin-left-right medium-font-2 secondary-color font-weight-700 ${styles.fontSize}`}
                                onClick={() => (history.push(RouterPathResolver.routeUser.path))}>
                                    {translator.decode("navbar.user")}
                                </p>
                            }
                        </div>
                        <LangSelector key="lang_selection" />
                        <div id={styles.dropdownMenu} className="center-content">
                            <Dropdown className={"d-inline mx-2"}>
                                <Dropdown.Toggle id="dropdown-autoclose-true" className={styles.toggle}>
                                    <Menu id={styles.menu}></Menu>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item onClick={signout} key="signout_option">{translator.decode("navbar.signout")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-height full-width flex-container flex-direction-row overflow-hidden">
                    {props.children}
            </div>
        </React.Fragment>
        );
};

export default NavBar;