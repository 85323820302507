/*
 * File: Form.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React, { useState } from "react";
import styles from "Content/Login/Form/Form.module.css";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import UserService from "Services/Users/UserService";
import { useHistory } from "react-router-dom";
import Input from "Theme/Component/Input";
import InputSubmit from "Theme/Component/InputSubmit";
import Alert from "react-bootstrap/Alert";
import translator from "MultiLanguage/TranslationWrapper";
import Link from "Theme/Component/Link";
import ServiceGeneral from 'Services/ServiceGeneral';

/**
 * Hook for the Form component of the login component
 * @param {Object} props The props passed to the component
 */
function Form(props) {
    const [ username, setName ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ loginError, setLoginError ] = useState(false);
    const history = useHistory();
    const return_url = props.returnUrl;

    /**
     * Handle submission of the form
     * @param {*} event The event object triggered when form submitted
     */
    const HandleSubmit =  async (event) => {
        event.preventDefault();
        var response = await UserService.login(username, password);
        ServiceGeneral.handleAxiosResponse(response);
        if (response.status !== 200 && response.status !== 304)
            setLoginError(response.data.message)
        else if (return_url !== undefined && return_url !== null) {
            history.push(return_url);
        }
        else {
            history.push(RouterPathResolver.pathEntryPoint());
        }
    }

    return (
        <div id={styles.div_form} className={"padded medium-round-border"}>
            <form className={"margined block"} onSubmit={HandleSubmit}>
                {loginError &&
                    <Alert variant="danger" className="margined block">
                        {translator.decode("login.error_message")}
                    </Alert>
                }
                <Input
                className={"block h-center-div full-width " + styles.input}
                type="text"
                value={username}
                placeholder={translator.decode("login.placeholder.username")}
                onChange={e => setName(e.target.value)}
                />
                <Input
                className={"block h-center-div full-width margin-bottom " + styles.input}
                type="password"
                value={password}
                placeholder={translator.decode("login.placeholder.password")}
                onChange={e => setPassword(e.target.value)}
                />
                <Link to={RouterPathResolver.routeForgotPassword.path}>
                    <p className="no-margin align-content-right">{translator.decode("login.forgotpassword")}</p>
                </Link>
                <InputSubmit
                className={"block h-center-div full-width font-weight-700 large-font-2 margin-bottom " + styles.input_submit}
                value={translator.decode("login.send")}/>
                <Link to={RouterPathResolver.routeLegal.path}>
                    <p className="no-margin">{translator.decode("login.legal")}</p>
                </Link>
            </form>
        </div>
    );
};

export default Form;