/*
 * File: InputSubmit.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */
import styled from "styled-components";

/**
* Styled component Input submit
* Type: input (provided via props)
*/
const Input = styled.input.attrs(props => ({
    type: "submit",
    className: "small-round-border medium_font_2",
}))`
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
padding-left: 10px;
color: ${props => props.variant === "positive" ? props.theme.colors.fifth : (props.variant === "negative" ? props.theme.colors.fifth : props.theme.colors.first)};
background-color: ${props => props.variant === "positive" ? props.theme.colors.sixth : (props.variant === "negative" ? props.theme.colors.second: (props.variant === "warning" ? props.theme.colors.seventh : props.theme.colors.fourth))};
border: 2px solid transparent;
&:hover, &:focus, &:click {
}
`;

export default Input;
