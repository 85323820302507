/*
 * File: Login.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import React from "react";
import styles from "Content/Login/Login.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import { useTheme } from "styled-components";
import { useLocation } from 'react-router-dom';
import Form from "Content/Login/Form/Form";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
/**
 * The Login component
 * @param {Object} props The propos passed to the hook
 */
function Login(props) {
    const theme = useTheme();
    const query = useQuery();

    return(
        <div className={"fullscreen center-content"}>
            <div>
                <img className={styles.logo + " margined"} src={theme.logos.logo_horizontal}
                alt={translator.decode("logo.description")}>
                </img>
                <p className="font-weight-700 small-font-2">{translator.decode("login.title")}</p>
                <img id={styles.france} className={styles.logo + "margined"} src={theme.images.france}
                alt={translator.decode("login.image.france.description")}>
                </img>
                <Form returnUrl={query.get("return_url")} />
            </div>
        </div>
    );
};

export default Login;