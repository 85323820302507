import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import MyModal from 'Content/Modal/MyModal';
import styles from 'Content/Modal/CarrousselPictures.module.css';
import 'Content/Modal/CarrousselPictures.css';

function CarrousselPictures(props) {
    return (<MyModal

    show={props.show}
    onClose={props.onClose}
    title={props.title}
    className={`${props.className} ${styles.carrousselContent}`}>
        <Carousel centerMode={true} centerSlidePercentage={95} infiniteLoop={true} >
            {props.pictures.map((item, idx) => {
                return (
                    <div className={styles.pictureDiv} key={`${props.tile}_picture_${idx}`}>
                        <img alt="" src={item.URI} className={styles.picture} max-width="50%" max-height="50%"/>
                    </div>
                )
                })
            }
        </Carousel>
    </MyModal>)
}

export default CarrousselPictures