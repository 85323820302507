import React from "react";
import {useEffect, useState } from "react";
import {AiOutlineCamera, AiOutlinePlus} from 'react-icons/ai';

import styles from "Content/Formulaire/CameraButton/CameraButton.module.css";
import Button from "Theme/Component/Button";

import translator from "MultiLanguage/TranslationWrapper";

import Picture from "Content/Formulaire/Picture/Picture";
import PicturesContainer from "Content/Formulaire/PicturesContainer/PicturesContainer";

function CameraButton(props) {
    const [clicked, setClicked] = useState(false);
    const [pictureContainers, setPictureContainers] = useState([]);
    const [childrenRef, setChildrenRef] = useState([]);
    const ref = React.createRef();

    useEffect(() => {
        setClicked(false);
    }, [clicked, props.pictures.length]);

    const handleClick = (e) => {
        props.pictures.push(null);
        const picIdx = props.pictures.length - 1;
        const containerIdx = Math.trunc(picIdx/2);
        var myRef = null;
        if (containerIdx >= pictureContainers.length) {
            // ref = useRef(null)
            childrenRef.push(ref);
            pictureContainers.push(<PicturesContainer ref={ref} key={`picContainer-${containerIdx}`} updated={clicked} children={[]} />)
            // pictureContainers.props.children
        } else {
            myRef  = pictureContainers[containerIdx].ref;
        }
        const pic = <Picture controlId={`costPic-${picIdx}`} inputId={`take-picCost-${picIdx}`} file={props.pictures[picIdx]} className="" onChange={(event) => {props.setPictures(picIdx, event.target.files[0]);}} placeholder={translator.decode("form.placeholder.pictures.cost")} required={false} />;
        if (myRef === null)
            pictureContainers[containerIdx].props.children.push(pic)
        else {
            myRef.current.addChild(pic);
        }
        setClicked(true);
    }

    return (
        <React.Fragment>
            <div className={`${styles.addedDiv}`}>
                <label htmlFor={"morePic"} className={`${styles.colorEmphasis} ${styles.centered} ${styles.label}`} >{translator.decode("form.button.title")}</label>
                <div>
                    { pictureContainers }
                </div>
                <Button type="button" id={"morePic"} variant={"primal"} className={`${styles.centered} ${styles.addedButton}`} onClick={handleClick}>
                    <span className={`${styles.iconGroup}`}>
                        <AiOutlineCamera className={`${styles.camera}`} />
                        <AiOutlinePlus className={`${styles.plus}`}/>
                    </span>
                </Button>
            </div>
        </React.Fragment>
    )
}

export default CameraButton;
