import { useImperativeHandle, useEffect, useState, Fragment, forwardRef } from "react";
import styles from "Content/Formulaire/PicturesContainer/PicturesContainer.module.css";

const PicturesContainer = forwardRef((props, ref) => {
    const [children, setChildren]= useState([null, undefined].includes(props.children) || props.children === undefined ? [] : [...props.children])
    const [ending, setEnding] = useState(false);

    useImperativeHandle(ref, (smth) => {
        return ({addChild: (child) => {
                setChildren([...children, child]);
                setEnding(true);
        }})
    })

    useEffect(() => {
        if (ending === false && children.length === 0) {
            setChildren([...props.children]);
            setEnding(true);
        } else if (ending === true && children.length === 2) {
            setChildren([...children]);
            setEnding(false);
        }
    }, [children]);

    return (
        <Fragment>
            <div className={`${styles.myRow}`}>
                <div className={`${styles.myCol} ${styles.centered } ${styles.inputContainer}`}>
                    {
                        children.length > 0 && children[0] !== null && children[0] !== undefined &&
                        children[0]
                    }
                </div>

                <div className={`${styles.myCol} ${styles.centered } ${styles.inputContainer}`}>
                    {
                        children.length > 0 && children[1] !== null && children[1] !== undefined &&
                        children[1]
                    }
                </div>
            </div>
        </Fragment>
    );
});

export default PicturesContainer;
