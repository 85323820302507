/*
 * File: Cookie.js
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

import Cookies from "universal-cookie";
import Config from "Config/Config";

const cookies = new Cookies();

/**
 * Wrapper class to handle Cookie
 */
class Cookie {
    /**
     * Set the cookie with the new data
     * @param {String} field The identifier of the cookie
     * @param {*} data The data to set
     */
    setCookie(field, data) {
        cookies.set(field, data, {sameSite: "strict", expires : new Date(Date.now() + Config.COOKIE.COOKIE_TIME_DAYS * 24 * 60 * 60 * 1000)});
    }

    /**
     * Get the cookie from identifier
     * @param {String} field The identifier of the cookie
     * @returns The cookie associated
     */
    getCookie(field) {
        return cookies.get(field);
    }

    /**
     * Removes a cookie
     * @param {String} field The identifier
     */
    removeCookie(field) {
        cookies.remove(field, {sameSite: "strict"});
    }
}

export default Cookie;